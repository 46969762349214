<template>
    <spinner :loading="loading">
        <div class="column">
            <div class="columns">
                <h1 class="title column">
                    Edit {{ library.name }}
                </h1>
                <div class="column is-clearfix is-3">
                    <div class="field is-grouped is-pulled-right">
                        <p class="control">
                            <button
                                class="button"
                                @click="cancel">
                                <b-icon
                                    icon="close-circle"
                                    size="is-small" />
                                <span>Cancel</span>
                            </button>
                        </p>
                        <p class="control">
                            <a
                                class="button is-primary"
                                :class="{'is-loading': saveLoading}"
                                @click="initSave">
                                <b-icon
                                    icon="content-save"
                                    size="is-small" />
                                <span>Save</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!loading">
            <b-tabs
                type="is-boxed"
                class="block">
                <b-tab-item label="Properties">
                    <div class="column has-margin-top2">
                        <div class="columns">
                            <div class="column is-two-fifths">
                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Description</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.description"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Definition</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.definition"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Alias</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.alias"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Alias for Name</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.nameAlias"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Regex for Name</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.codeNameRegex"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Regex for Description</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.descriptionRegex"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Specialised UI</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-select
                                                    v-model="libraryEdited.specializedUI"
                                                    placeholder="Select specialised UI">
                                                    <option :value="null">
                                                        No specialised UI
                                                    </option>
                                                    <option
                                                        v-for="option in specializedUIs"
                                                        :key="option"
                                                        :value="option">
                                                        {{ option }}
                                                    </option>
                                                </b-select>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Library type</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-select
                                                    v-model="libraryEdited.isForeignObject">
                                                    <option
                                                        v-for="type in libraryTypeOptions"
                                                        :key="type.value"
                                                        :value="type.value">
                                                        {{ type.name }}
                                                    </option>
                                                </b-select>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Foreign app name</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <input
                                                    v-model="libraryEdited.foreignAppName"
                                                    class="input"
                                                    type="text">
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <p
                                    v-for="error in libraryErrors"
                                    :key="error"
                                    class="has-text-danger">
                                    {{ error }}
                                </p>
                            </div>

                            <div class="column is-one-fifth">
                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.descriptionIsRequired"
                                                    type="is-info" />
                                                <strong>Description required</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.isGlobal"
                                                    type="is-info" />
                                                <strong>Is global</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isGlobal"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        <label class="label">Scope type</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-select
                                                    v-model="libraryEdited.scopeType"
                                                    placeholder="Select scope type">
                                                    <option
                                                        v-for="st in filteredScopeTypes"
                                                        :key="st"
                                                        :value="st">
                                                        {{ st }}
                                                    </option>
                                                </b-select>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isGlobal"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.isScopeSpecific"
                                                    type="is-info" />
                                                <strong>Is scope specific</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.isCaseSensitive"
                                                    type="is-info" />
                                                <strong>Is case sensitive</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.isVirtual"
                                                    :disabled="!libraryEdited.isGlobal"
                                                    type="is-info" />
                                                <strong>Is virtual</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.isReadOnly"
                                                    type="is-info" />
                                                <strong>Is read only</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.nameInIdentity"
                                                    type="is-info" />
                                                <strong>Name in identity</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.nameMayChange"
                                                    :disabled="libraryEdited.nameInIdentity"
                                                    title="Can only be set if Name is not part of identity"
                                                    type="is-info" />
                                                <strong>Name may be changed</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-show="!libraryEdited.isForeignObject"
                                    class="field is-horizontal">
                                    <div class="field-label">
                                        &nbsp;
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <p class="control">
                                                <b-checkbox
                                                    v-model="libraryEdited.areNamesUpperCase"
                                                    type="is-info" />
                                                <strong>UpperCase Names</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="column">
                                <div class="columns is-multiline">
                                    <div class="column is-full">
                                        <h2 class="title is-5">
                                            Library Groups
                                        </h2>
                                        <tag-list
                                            :icon-class="'fa fa-trash'"
                                            :tags="libraryEdited.tags"
                                            @click-tag="removeTag" />
                                        <b-autocomplete
                                            v-model="tagText"
                                            :data="filteredExistingTags"
                                            expanded
                                            placeholder="select existing or add new library group"
                                            :open-on-focus="true"
                                            @select="addTag">
                                            <template #header>
                                                <a @click="addNewTag">
                                                    <span> Add new library group </span>
                                                </a>
                                            </template>
                                            <template #empty>
                                                No existing library groups found
                                            </template>
                                        </b-autocomplete>
                                    </div>

                                    <div class="column">
                                        <p class="title is-5">
                                            Access Groups
                                        </p>
                                        <tag-list
                                            v-require-is-administrator
                                            :icon-class="'fa fa-trash'"
                                            type="is-danger"
                                            :tags="libraryEdited.accessGroups"
                                            @click-tag="removeAccessGroup" />
                                        <b-field grouped>
                                            <b-autocomplete
                                                v-model="agText"
                                                :data="filteredAccessGroups"
                                                expanded
                                                placeholder="select existing access group"
                                                :open-on-focus="true"
                                                @select="addAccessGroup">
                                                <template #empty>
                                                    No existing access group found
                                                </template>
                                            </b-autocomplete>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    :visible="libraryEdited.isVirtual"
                    label="Virtual library options">
                    <div class="columns">
                        <div class="column is-three-fifths">
                            <b-notification
                                v-show="virtualLibraryCompilerError"
                                type="is-danger"
                                :closable="false"
                                has-icon
                                role="alert">
                                <b>Query issue</b><br>
                                {{ virtualLibraryCompilerError }}
                            </b-notification>
                            <b-notification
                                v-show="virtualLibraryCompilerWarning"
                                type="is-warning"
                                :closable="false"
                                has-icon
                                role="alert">
                                <b>Potential query issue</b><br>
                                {{ virtualLibraryCompilerWarning }}
                            </b-notification>
                            <b-notification
                                v-for="n in virtualLibraryOptionsValidationResult?.compilerNotifications"
                                :key="n"
                                type="is-info"
                                :closable="false"
                                has-icon
                                role="alert">
                                <b>Please note</b><br>
                                {{ n }}
                            </b-notification>
                            <b-notification
                                v-show="virtualLibraryOptionsValidationResult?.dependsOnLibraries.length > 0"
                                :closable="false"
                                has-icon
                                icon="graph"
                                role="alert">
                                <b>The source query depends on the following libraries:</b><br>
                                <span
                                    v-for="d in virtualLibraryOptionsValidationResult?.dependsOnLibraries"
                                    :key="d"
                                    class="tag is-info mr-2 is-medium">
                                    {{ d }}
                                </span>
                            </b-notification>
                            <b-field
                                label="Scope column">
                                <b-input
                                    v-model="virtualLibraryOptions.scopeColumn" />
                            </b-field>
                            <b-field
                                label="Source query">
                                <b-input
                                    v-model="virtualLibraryOptions.sourceQuery"
                                    type="textarea"
                                    rows="10"
                                    custom-class="sql-editor" />
                            </b-field>
                            <b-field>
                                <b-button
                                    type="is-primary"
                                    icon-left="auto-fix"
                                    :loading="virtualLibraryOptionsValidationRunning"
                                    @click="validateVirtualLibraryOptionsHandler">
                                    Validate
                                </b-button>
                            </b-field>
                            <p v-if="virtualLibraryOptions.exists">
                                Virtual Library options last updated {{ $filters.dateFormatLong(virtualLibraryOptions.dateUpdated) }} UTC.
                            </p>
                            <p v-else>
                                Options not yet saved.
                            </p>
                        </div>
                        <div class="column">
                            <h3 class="title is-5">
                                Defining a virtual library
                            </h3>
                            <p class="block">
                                A virtual library is based on any query (the source query) over the data from other libraries.
                                You may join in any libraries you need, use union, etc.
                            </p>
                            <p class="block">
                                The source query <i>must</i> have the following columns:
                            </p>
                            <ul
                                class="block bullet-list">
                                <li>
                                    <b>Identity</b> - should if possible be a unique string
                                </li>
                                <li>
                                    <b>Name</b>
                                </li>
                            </ul>
                            <p class="block">
                                The following columns are <i>recommended</i>, and may be added automtically if not defined in the query:
                            </p>
                            <ul
                                class="block bullet-list">
                                <li>
                                    <b>Description</b>
                                </li>
                                <li>
                                    <b>IsValid</b> - must be of datatype <code>bit</code>.
                                </li>
                                <li>
                                    <b>DateUpdated</b> - is required if you want to use subscriptions or webhooks. May be added automatically for simple source queries.
                                </li>
                            </ul>
                            <p class="block">
                                One of the columns you add may be used as a <b>scope column</b>. If the scope column is called <code>Facility</code> it can be used as a scope when publishing changes to STID, TIE, etc.
                            </p>
                            <p class="block">
                                When you have prepared the source query, click the <b>Validate</b> button to verify. Then click <b>Save</b> when you are satisfied.
                            </p>
                            <p class="block">
                                Example source query:
                            </p>
                            <pre>
SELECT
   concat_ws('-', pf.facility, pc.Project, c.Name) as Name
  ,concat_ws('|', pf.facility, pc.Project, c.Name) as [Identity]
  ,CONVERT(bit, 1) as IsValid
  ,pf.facility as Facility
  ,p.Name as Project, p.description as ProjectDescription
  ,c.Name as Contract, c.Description as ContractDescription
  ,pc.PIMSDCDomain
  ,GREATEST(pc.DateUpdated, pf.DateUpdated, c.DateUpdated, p.DateUpdated) as DateUpdated
FROM ProjectContract pc
  join ProjectFacility pf on pf.project_id = pc.project_id and pf.IsValid = 1
  join contract c on c.id = pc.Contract_ID
  join project p on p.id = pc.Project_ID
WHERE pc.IsValid = 1
                            </pre>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    v-if="!libraryEdited.isForeignObject"
                    label="Attribute definitions">
                    <div class="column is-clearfix">
                        <div class="field is-pulled-right">
                            <p class="control">
                                <button
                                    class="button is-info"
                                    @click="newAD">
                                    <b-icon
                                        icon="plus-circle-outline"
                                        size="is-small" />
                                    <span>New attribute definition</span>
                                </button>
                            </p>
                        </div>
                    </div>
                    <attribute-def-edit
                        ref="attributeEdit"
                        :attributedefs="libraryEdited.attributeDefinitions"
                        :library="libraryEdited" />
                </b-tab-item>
                <b-tab-item
                    v-if="!libraryEdited.isForeignObject"
                    label="Governance and documentation">
                    <item-metadata
                        ref="libraryInformationElement"
                        :selected-name="libraryName"
                        :save-button-enabled="false"
                        :save-message-enabled="false"
                        type="libraryInformationElement" />
                </b-tab-item>
            </b-tabs>
        </div>
    </spinner>
</template>

<script>
    import _ from 'lodash';
    import AttributeDefEdit from './AttributeDefEdit.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import TagList from '@/shared/components/TagList.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { showMixin } from '@/shared/mixins/showMixin';
    import {
        untagLibrary,
        getAllTags,
        tagLibrary,
        postAccessGroupLibrary,
        deleteAccessGroupLibrary,
        patchLibrary,
        getAccessGroups,
        getAttributeDefinitions,
        getScopeTypes,
        getLibrary,
        getSpecializedUIs,
        getVirtualLibraryOptions,
        validateVirtualLibraryOptions,
        postVirtualLibraryOptions
    } from '@/shared/helpers/api';
    import ItemMetadata from '@/shared/components/ItemMetadata.vue';

    export default {
        components: {
            ItemMetadata,
            AttributeDefEdit,
            Spinner,
            TagList
        },
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        mixins: [
            showMixin
        ],
        props: {
            libraryName: {
                type: String,
                required: true
            }
        },
        emits: [
            'done'
        ],
        data() {
            return {
                saveLoading: false,
                loading: true,
                library: null,
                libraryEdited: null,
                accessGroups: [],
                libraryErrors: [],
                libraryTypeOptions: [
                    { name: 'Normal', value: false },
                    { name: 'Foreign object', value: true }
                ],
                existingTags: null,
                tagText: '',
                agText: '',
                scopeTypes: [],
                specializedUIs: [],
                virtualLibraryOptions: null,
                virtualLibraryOptionsValidationResult: null,
                virtualLibraryOptionsValidationRunning: false
            };
        },
        computed: {
            filteredExistingTags() {
                if (this.existingTags) {
                    const tagText = this.tagText.toLowerCase();
                    return this.existingTags
                        .filter(t => !this.libraryEdited.tags.includes(t))
                        .filter(t => tagText === '' ? true : t.toLowerCase().includes(tagText))
                        .sort((a, b) => a > b ? 1 : -1);
                } else {
                    return [];
                }
            },
            filteredAccessGroups() {
                if (this.accessGroups) {
                    const agText = this.agText.toLowerCase();
                    return this.accessGroups
                        .map(g => g.name)
                        .filter(g => !this.libraryEdited.accessGroups.includes(g))
                        .filter(g => g.toLowerCase().includes(agText))
                        .sort((a, b) => a > b ? 1 : -1);
                } else {
                    return [];
                }
            },
            filteredScopeTypes() {
                return this.scopeTypes.filter(x => x !== this.library.name);
            },
            virtualLibraryCompilerError() {
                return this.virtualLibraryOptionsValidationResult
                    ? this.virtualLibraryOptionsValidationResult.compilerError
                    : this.virtualLibraryOptions?.compilerError;
            },
            virtualLibraryCompilerWarning() {
                return this.virtualLibraryOptionsValidationResult
                    ? this.virtualLibraryOptionsValidationResult.compilerWarning
                    : this.virtualLibraryOptions?.compilerWarning;
            }
        },
        async mounted() {
            const [
                library,
                attributeDefinitions,
                existingTags,
                accessGroups,
                scopeTypes,
                specializedUIs,
                virtualLibraryOptions
            ] = await Promise.all([
                getLibrary(this, this.libraryName),
                getAttributeDefinitions(this, this.libraryName),
                getAllTags(this),
                getAccessGroups(this),
                getScopeTypes(this),
                getSpecializedUIs(this),
                getVirtualLibraryOptions(this, this.libraryName)
            ]);

            this.library = library;
            this.libraryEdited = JSON.parse(JSON.stringify(this.library));
            this.libraryEdited.attributeDefinitions = attributeDefinitions;
            this.existingTags = existingTags;
            this.accessGroups = accessGroups;
            this.scopeTypes = scopeTypes.sort();
            this.specializedUIs = specializedUIs;
            this.virtualLibraryOptions = virtualLibraryOptions;
            this.loading = false;
        },
        methods: {
            async validateVirtualLibraryOptionsHandler() {
                this.virtualLibraryOptionsValidationRunning = true;
                try {
                    this.virtualLibraryOptionsValidationResult = (await validateVirtualLibraryOptions(this, this.virtualLibraryOptions)).data;
                } catch (ex) {
                    this.showError(ex);
                }
                this.virtualLibraryOptionsValidationRunning = false;
            },
            addNewTag() {
                this.$buefy.dialog.prompt({
                    message: 'New Tag Name:',
                    inputAttrs: {
                        minlength: 1,
                        maxlength: 64,
                        required: true
                    },
                    onConfirm: this.addTag
                });
            },
            addTag(tag) {
                if (!this.libraryEdited.tags.includes(tag) && tag !== null) {
                    this.libraryEdited.tags.push(tag);
                    setTimeout(() => this.tagText = '', 100);
                }
            },
            removeTag(tag) {
                this.libraryEdited.tags.splice(this.libraryEdited.tags.indexOf(tag), 1);
            },
            removeAccessGroup(group) {
                this.libraryEdited.accessGroups.splice(this.libraryEdited.accessGroups.indexOf(group), 1);
            },
            addAccessGroup(group) {
                if (!group)
                    return;
                this.libraryEdited.accessGroups.push(group);
                setTimeout(() => this.agText = '', 100);
            },
            async initSave() {
                this.saveLoading = true;
                const diff = (a1, a2) => ({ new: a1.filter(x => !a2.includes(x)), del: a2.filter(x => !a1.includes(x)) });
                const tagDiff = diff(this.libraryEdited.tags, this.library.tags);
                const agDiff = diff(this.libraryEdited.accessGroups, this.library.accessGroups);
                try {
                    let updateVirtualLibOptions = true;
                    if (!this.libraryEdited.isForeignObject)
                        await this.$refs.attributeEdit.updateAttributes();
                    if (this.libraryEdited.isVirtual)
                        updateVirtualLibOptions = await this.updateVirtualLibraryOptions();
                    const updatedLib = await this.updateLibrary();
                    const updatedTag = await this.updateTags(tagDiff);
                    const updatedAg = await this.updateAg(agDiff);
                    const updatedLibInfo = await this.updateLibInfo();

                    if (updatedLib && updatedTag && updatedAg && updatedLibInfo && updateVirtualLibOptions) {
                        this.$emit('done');
                    }
                    this.saveLoading = false;
                } catch (err) {
                    this.showError(err);
                    this.saveLoading = false;
                }
            },
            async updateTags(diff) {
                try {
                    await Promise.all(diff.new.map(tag => tagLibrary(this, tag, this.library.name)));
                    await Promise.all(diff.del.map(tag => untagLibrary(this, tag, this.library.name)));
                    return true;
                } catch {
                    this.showError('Error in updating library tags');
                    return false;
                }
            },
            async updateAg(diff) {
                try {
                    await Promise.all(diff.new.map(ag => postAccessGroupLibrary(this, ag, this.library.name)));
                    await Promise.all(diff.del.map(ag => deleteAccessGroupLibrary(this, ag, this.library.name)));
                    return true;
                } catch {
                    this.showError('Error in updating access groups');
                    return false;
                }
            },
            async updateLibInfo() {
                if (!await this.$refs.libraryInformationElement.save()) {
                    this.showError('Error in updating governance and documentation');
                    return false;
                }
                return true;
            },
            async updateVirtualLibraryOptions() {
                try {
                    await postVirtualLibraryOptions(this, this.library.name, this.virtualLibraryOptions);
                    return true;
                } catch {
                    this.showError('Error updating virtual library options');
                    return false;
                }
            },
            async updateLibrary() {
                const {
                    name,
                    description,
                    definition,
                    codeNameRegex,
                    descriptionRegex,
                    alias,
                    nameAlias,
                    specializedUI,
                    isValid,
                    isCaseSensitive,
                    isReadOnly,
                    isVirtual,
                    areNamesUpperCase,
                    nameInIdentity,
                    nameMayChange,
                    isForeignObject,
                    foreignAppName,
                    isGlobal,
                    isScopeSpecific,
                    scopeType,
                    descriptionIsRequired
                } = this.libraryEdited;

                const command = {
                    name,
                    description,
                    definition,
                    codeNameRegex,
                    descriptionRegex,
                    alias,
                    nameAlias,
                    specializedUI,
                    isValid,
                    isCaseSensitive,
                    isReadOnly,
                    isVirtual,
                    areNamesUpperCase,
                    nameInIdentity,
                    nameMayChange,
                    isForeignObject,
                    foreignAppName,
                    isGlobal,
                    isScopeSpecific,
                    scopeType,
                    descriptionIsRequired
                };

                try {
                    await patchLibrary(this, this.library.name, command);
                    return true;
                } catch (err) {
                    this.libraryErrors = err.response.data.errors;
                    this.showError(_.get(err, 'response.data.Message', 'unable to update library'));

                    // Sleep 4 sec to delay page reload's so that the user can read error message
                    await new Promise(r => setTimeout(r, 4000));
                    return false;
                }
            },
            newAD() {
                this.$refs.attributeEdit.newAD();
            },
            cancel() {
                this.$emit('done');
            }
        }

    };
</script>

<script setup>
    import { newFormatElement, useBuefy, ReferencePresence } from '@/apps/ens/helpers.ts';
    import { requireCanEditCode as vRequireCanEditCode } from '@/shared/directives/requirePermission';
    import { spaceDecode, spaceEncode } from '@/shared/helpers/spaceEncoding.js';
    import _ from 'lodash';
    import { nextTick, ref, watch } from 'vue';
    import EnsFormatValidationResult from './EnsFormatValidationResult.vue';

    const buefy = useBuefy();

    const emit = defineEmits(['change']);
    const props = defineProps({
        ensType: {
            type: String,
            required: true
        },
        formatName: {
            type: String,
            default: null
        },
        isFormatNew: {
            type: Boolean,
            required: true
        },
        initialElements: {
            type: Array,
            required: true
        },
        layouts: {
            type: Array,
            required: true
        },
        references: {
            type: Array,
            required: true
        },
        paddings: {
            type: Array,
            default: null
        },
        permissionsObject: {
            type: Object,
            default: null
        },
        descriptionRegexValidator: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        validationLoading: {
            type: Boolean,
            required: true
        },
        validationResult: {
            type: Object,
            default: null
        },
        validationError: {
            type: String,
            default: null
        }
    });

    const elements = ref(null);
    const draggingRow = ref(null);
    const draggingRowIndex = ref(null);
    const currentDraggingElement = ref(null);
    const inputs = ref({});

    function updateRef(key) {
        return (el) => {
            if (el) {
                inputs.value[key] = el;
            } else {
                delete inputs.value[key];
            }
        };
    }

    function elementReferenceIsDuplicate(element) {
        return element.reference === '0'
            || element.reference === '27' && props.ensType === 'TagFormat'
            || element.reference === '21' && props.ensType === 'DocumentNumberingFormat'
            || element.constantValue
            || !elements.value.some(e =>
                e.elementNo !== element.elementNo
                && !element.constantValue
                && !e.constantValue
                && e.reference === element.reference);
    }

    function validateReferences() {
        elements.value.forEach(element => {
            updateReferenceError(element);
            updateReferencePresenceError(element);
        });
    }

    function updateReferenceError(element) {
        element.referenceError
            = elementReferenceIsDuplicate(element) ? '' : 'Duplicate reference';
    }

    function updateReferencePresenceError(element) {
        if (isReferenceMandatory(element) && !element.required) {
            element.referenceMandatoryError = 'Mandatory for selected reference';
        } else if (isReferenceRecommended(element) && !element.required) {
            element.referenceRecommendedWarning = 'Recommended for selected reference';
        } else {
            element.referenceMandatoryError = '';
            element.referenceRecommendedWarning = '';
        }
    }

    function findReferencePresence(element) {
        return props.references.find(r => r.identity === element.reference)?.presence;
    }

    function isReferenceMandatory(element) {
        return findReferencePresence(element) === ReferencePresence.Mandatory;
    }

    function isReferenceRecommended(element) {
        return findReferencePresence(element) === ReferencePresence.Recommended;
    }

    function setRequiredFieldFromReferencePresence(element) {
        const presence = findReferencePresence(element);
        element.required = presence === ReferencePresence.Mandatory || presence === ReferencePresence.Recommended;
    }

    function isDisabledRequired(element) {
        return isReferenceMandatory(element) && element.required;
    }

    function validateLayouts() {
        elements.value.forEach(updateLayoutError);
    }

    function updateLayoutError(element) {
        element.layoutError = element.reference === '9' && element.layout !== 'N'
            ? 'Layout must be numeric for sequence'
            : '';
    }

    function validateDescription() {
        if (!props.descriptionRegexValidator)
            return;
        elements.value.forEach(element => {
            const error = props.descriptionRegexValidator.validate(element.description);
            element.descriptionError = error?.error || '';
        });
    }

    function validateMinMax() {
        return Promise.all(elements.value.map(updateMinMaxError));
    }

    async function updateMinMaxError(element) {
        // Have to use nextTick in order for elements to catch up with model changes
        await nextTick();
        const isMinValid = inputs.value[element.elementNo + '-min'].checkHtml5Validity();
        const isMaxValid = inputs.value[element.elementNo + '-max'].checkHtml5Validity();
        element.minMaxError = !isMinValid || !isMaxValid;
    }

    async function onElementMinMaxChanged(element) {
        await updateMinMaxError(element);
        onElementsChanged();
    }

    function onElementReferenceChanged(element) {
        validateReferences();
        setRequiredFieldFromReferencePresence(element);

        // If reference is "Sequence (#9)" then set layout to "N - Numeric"
        if (element.reference === '9') {
            element.layout = 'N';
        }

        onElementsChanged();
    }

    function onElementLayoutChanged(element) {
        updateLayoutError(element);
        onElementsChanged();
    }

    function onElementDescriptionChanged() {
        validateDescription();
        onElementsChanged();
    }

    function onConstantValueChanged(element, newValue) {
        element.constantValue = spaceDecode(newValue);
        onElementsChanged();
    }

    function onClickRemoveRow(element) {
        buefy.dialog.confirm({
            message: 'Are you sure you want to remove this row?',
            cancelText: 'Cancel',
            confirmText: 'Yes',
            onConfirm: () => removeRow(element.elementNo)
        });
    }

    function addNewRow() {
        const elementNo = elements.value.length;
        const element = newFormatElement(props.ensType, elementNo);
        elements.value.push(element);
        onElementsChanged();
    }

    function removeRow(elementNo) {
        elements.value = elements.value
            .filter(element => element.elementNo !== elementNo)
            .map((element, index) => ({ ...element, elementNo: index }));
        validateReferences();
        onElementsChanged();
    }

    function dragstart(payload) {
        if (!isOnGrabHandle(payload.event)) {
            payload.event.preventDefault();
            return;
        }

        currentDraggingElement.value = payload.event.target.closest('tr');
        draggingRow.value = payload.row;
        draggingRowIndex.value = payload.index;
        payload.event.dataTransfer.effectAllowed = 'move';
    }

    function isOnGrabHandle(event) {
        const grabber = event.target.querySelector('.grab-handle');
        return event.clientX <= grabber.getBoundingClientRect().right;
    }

    function dragover(payload) {
        if (currentDraggingElement.value !== payload.event.target.closest('tr')) {
            payload.event.dataTransfer.dropEffect = 'move';
            payload.event.target.closest('tr').classList.add('is-selected');
        }
        payload.event.preventDefault();
    }

    function dragleave(payload) {
        payload.event.target.closest('tr').classList.remove('is-selected');
        payload.event.preventDefault();
    }

    function dragend() {
        currentDraggingElement.value = null;
    }

    function drop(payload) {
        payload.event.target.closest('tr').classList.remove('is-selected');
        if (payload.index !== draggingRowIndex.value) {
            moveElement(draggingRowIndex.value, payload.index);
            onElementsChanged();
        }
    }

    function moveElement(startIdx, stopIdx) {
        const newElements = elements.value.map(x => ({ ...x }));
        const movedElement = newElements[startIdx];
        if (startIdx > stopIdx) {
            shiftUp(newElements, startIdx, stopIdx);
        } else {
            shiftDown(newElements, startIdx, stopIdx);
        }
        newElements[stopIdx] = movedElement;
        newElements[stopIdx].elementNo = stopIdx;
        elements.value = newElements;
    }

    function shiftDown(elements, startIdx, stopIdx) {
        for (let i = startIdx; i < stopIdx; i++) {
            elements[i] = elements[i + 1];
            elements[i].elementNo = elements[i].elementNo - 1;
        }
    }

    function shiftUp(elements, startIdx, stopIdx) {
        for (let i = startIdx; i > stopIdx; i--) {
            elements[i] = elements[i - 1];
            elements[i].elementNo = elements[i].elementNo + 1;
        }
    }

    function onElementsChanged() {
        emit('change', elements.value);
    }

    watch(() => props.initialElements, async (initialElements) => {
        elements.value = initialElements.map(x => ({ ...x })); // Clone one level deep
        validateReferences();
        validateLayouts();
        validateDescription();
        await validateMinMax();
        if (!_.isEqual(elements.value, initialElements)) {
            onElementsChanged();
        }
    }, { immediate: true });
</script>

<template>
    <div>
        <b-table
            narrowed
            :striped="true"
            :hoverable="true"
            :loading="loading"
            :data="elements"
            class="table"
            draggable
            :mobile-cards="false"
            @dragstart="dragstart"
            @drop="drop"
            @dragover="dragover"
            @dragleave="dragleave"
            @dragend="dragend">
            <!-- Drag icons -->
            <b-table-column>
                <div
                    class="grab-handle grab moving mdi mdi-drag mdi-18px"
                    aria-hidden="true" />
            </b-table-column>
            <!-- Description -->
            <b-table-column
                v-slot="slotProps"
                width="20%"
                label="Description">
                <b-field
                    style="min-width: 15ch"
                    :message="slotProps.row.descriptionError"
                    :type="slotProps.row.descriptionError ? 'is-danger': ''">
                    <b-input
                        v-model="slotProps.row.description"
                        type="text"
                        :maxlength="slotProps.row.descriptionError ? props.descriptionRegexValidator?.maxLength : undefined"
                        @update:model-value="onElementDescriptionChanged" />
                </b-field>
            </b-table-column>
            <!-- Minimum characters -->
            <b-table-column
                width="5%"
                label="Minimum Characters">
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Minimum number of characters
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field>
                        <b-input
                            :ref="updateRef(slotProps.row.elementNo + '-min')"
                            v-model.number="slotProps.row.minimumCharacters"
                            type="number"
                            required
                            min="0"
                            :max="slotProps.row.maximumCharacters"
                            @update:model-value="onElementMinMaxChanged(slotProps.row)" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- Maximum characters -->
            <b-table-column
                width="5%"
                label="Maximum Characters">
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Maximum number of characters
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field>
                        <b-input
                            :ref="updateRef(slotProps.row.elementNo + '-max')"
                            v-model.number="slotProps.row.maximumCharacters"
                            type="number"
                            required
                            :min="slotProps.row.minimumCharacters"
                            @update:model-value="onElementMinMaxChanged(slotProps.row)" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- In Tag/Doc No -->
            <b-table-column
                :label="'In ENS\xa0no'"
                centered>
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Include in ENS number
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field>
                        <b-checkbox
                            v-model="slotProps.row.include"
                            type="is-light"
                            :true-value="true"
                            :false-value="false"
                            @update:model-value="onElementsChanged" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- Show -->
            <b-table-column
                label="Show"
                centered>
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Show
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field>
                        <b-checkbox
                            v-model="slotProps.row.show"
                            type="is-light"
                            :true-value="true"
                            :false-value="false"
                            @update:model-value="onElementsChanged" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- Required -->
            <b-table-column
                label="Required"
                centered>
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Required
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field
                        :message="slotProps.row.referenceMandatoryError || ''"
                        :type="slotProps.row.referenceMandatoryError ? 'is-danger': ''">
                        <b-checkbox
                            v-model="slotProps.row.required"
                            :disabled="isDisabledRequired(slotProps.row)"
                            type="is-light"
                            :true-value="true"
                            :false-value="false"
                            @update:model-value="onElementsChanged" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- Select flag -->
            <b-table-column
                label="Search flag"
                centered>
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Select flag in STID
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field>
                        <b-checkbox
                            v-model="slotProps.row.selectFlag"
                            type="is-light"
                            :true-value="true"
                            :false-value="false"
                            @update:model-value="onElementsChanged" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- Constant value -->
            <b-table-column
                label="Constant value">
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Constant value
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field>
                        <b-input
                            :model-value="spaceEncode(slotProps.row.constantValue)"
                            type="text"
                            @update:model-value="newValue => onConstantValueChanged(slotProps.row, newValue)" />
                    </b-field>
                </template>
            </b-table-column>
            <!-- Layout -->
            <b-table-column
                label="Layout">
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Layout / character set
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <div>
                        <b-field
                            :message="slotProps.row.layoutError"
                            :type="slotProps.row.layoutError ? 'is-danger': ''">
                            <b-select
                                v-model="slotProps.row.layout"
                                required
                                @update:model-value="onElementLayoutChanged(slotProps.row)">
                                <option
                                    v-for="layout in layouts"
                                    :key="layout.identity"
                                    :value="layout.identity">
                                    {{ layout.name }} - {{ layout.description }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </template>
            </b-table-column>
            <!-- Reference -->
            <b-table-column
                label="Reference">
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Reference
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template #default="slotProps">
                    <b-field
                        :message="slotProps.row.referenceError"
                        :type="slotProps.row.referenceError ? 'is-danger': ''">
                        <b-select
                            v-model="slotProps.row.reference"
                            @update:model-value="onElementReferenceChanged(slotProps.row)">
                            <option
                                v-for="reference in references"
                                :key="reference.identity"
                                :value="reference.identity">
                                {{ reference.description }} (#{{ reference.name }}) {{ reference.presence!=null ? `(${reference.presence})` : '' }}
                            </option>
                        </b-select>
                    </b-field>
                </template>
            </b-table-column>
            <!-- Padding -->
            <b-table-column
                v-if="ensType === 'TagFormat'"
                label="Padding">
                <template #header="{ column }">
                    <b-tooltip
                        position="is-left"
                        size="is-small"
                        multilined
                        :delay="500"
                        animated
                        class="tooltip">
                        <div>
                            {{ column.label }}
                        </div>
                        <template #content>
                            <div class="tooltip-body">
                                Padding with whitespace (Left or right) (Justify in STID)
                            </div>
                        </template>
                    </b-tooltip>
                </template>
                <template
                    #default="slotProps">
                    <b-field>
                        <b-select
                            v-model="slotProps.row.padding"
                            @update:model-value="onElementsChanged">
                            <option :value="null" />
                            <option
                                v-for="padding in paddings"
                                :key="padding.identity"
                                :value="padding.identity">
                                {{ padding.description }}
                            </option>
                        </b-select>
                    </b-field>
                </template>
            </b-table-column>
            <b-table-column
                v-slot="slotProps">
                <b-field>
                    <b-tooltip
                        position="is-left"
                        label="Remove row">
                        <b-button
                            :icon-left="'delete'"
                            @click="onClickRemoveRow(slotProps.row)" />
                    </b-tooltip>
                </b-field>
            </b-table-column>
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>No elements</p>
                    </div>
                </section>
            </template>
        </b-table>

        <div
            class="bottom-toolbar">
            <div
                class="block is-right-justified">
                <b-field grouped>
                    <b-field>
                        <p class="control">
                            <button
                                v-require-can-edit-code="permissionsObject"
                                class="button"
                                @click="addNewRow()">
                                <b-icon
                                    icon="plus"
                                    size="is-small" />
                                <span>Add row</span>
                            </button>
                        </p>
                    </b-field>
                </b-field>
            </div>
            <ens-format-validation-result
                class="block"
                :loading="validationLoading"
                :result="validationResult"
                :error="validationError" />
        </div>
    </div>
</template>

<style scoped>
.grab:hover {
    cursor: grab;
}
.tooltip{
    cursor: help;
}
.tooltip-body {
    font-size: 16px;
    color:white;
}
.table {
    overflow: auto;
    max-height: 75vh;
}

.table :deep(td) {
    vertical-align: middle;
}

.bottom-toolbar {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: left;
}
.is-right-justified {
    margin-left: auto;
}

/* begin b-checkbox overrides */
.table :deep(.b-checkbox.checkbox) {
    display: flex; /* and not inline-flex */
    justify-content: center;
}
.table :deep(.b-checkbox.checkbox .control-label) {
    padding-left: 0;
}
.table :deep(.b-checkbox.checkbox:not(.button)) {
    margin-right: 0;
}
/* end b-checkbox overrides */

.min-width-60 {
    min-width: 60px;
}
</style>

<script setup>
    import { ref, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import saveAs from 'file-saver';
    import { http } from '@/shared/httpWrapper.js';
    import { sqlQuery, sqlQueryToExcel } from '@/shared/helpers/api';
    import { getCodeLinkByB64Id } from '@/shared/helpers/routing';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import useShow from '@/shared/composables/useShow.js';
    import _ from 'lodash';

    const { showError } = useShow();

    /* DATA */

    const route = useRoute();
    const query = route.query.query;
    // 2024-12-13
    // Some external system incorrectly replace the & before the title and pageSize parameters with &amp;, see PR#1578
    // Checking for 'amp;<parameter>' is a quick fix which allows the parameters to still be read from the url even if malformed
    // This fix should not be replicated elsewhere without team discussion
    const pageTitle = route.query.title || route.query['amp;title'] || 'Query result';
    const pageSize = parseInt(route.query.pageSize) || parseInt(route.query['amp;pageSize']) || 30;

    const displayQuery = ref(false);
    const loading = ref(false);
    const errorMessage = ref(null);

    const result = ref();
    const resultColumns = computed(() => {
        if (result.value && result.value.length) {
            const fieldNames = _.keys(result.value[0]);
            return _.map(fieldNames, fn => ({
                field: fn,
                label: fn,
                sortable: true
            }));
        }
        return [];
    });

    /* HELPERS */

    function codeLinkFromDbId(id) {
        return getCodeLinkByB64Id(encodeIdBase64('Code', id));
    }

    /* EVENT HANDLERS */

    function toggleDisplayQuery() {
        displayQuery.value = !displayQuery.value;
    }

    async function runExport() {
        try {
            const response = await sqlQueryToExcel(http, { query });

            if (response.data) {
                const filename = pageTitle + '.xlsx';
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                saveAs(blob, filename);
            }
        } catch (ex) {
            showError(ex);
        }
    }

    /* INITIALIZATION */

    (async function init() {
        if (!query) {
            errorMessage.value = 'This page expects parameter "query" with a SQL expression retrieving library data.';
            return;
        }

        try {
            loading.value = true;
            errorMessage.value = null;
            result.value = (await sqlQuery(http, { query })).data;
        } catch (ex) {
            showError(ex, m => { errorMessage.value = m; });
        }

        loading.value = false;
    })();
</script>

<template>
    <div class="columns">
        <div class="column">
            <h1 class="title">
                <b-icon
                    icon="table-search"
                    size="" />
                {{ pageTitle }}
            </h1>
        </div>
        <div class="column">
            <div
                class="field is-grouped mt-3 is-pulled-right">
                <div class="control buttons has-addons">
                    <b-button
                        icon-left="magnify"
                        :active="displayQuery"
                        @click="toggleDisplayQuery">
                        Show query
                    </b-button>
                    <b-button
                        icon-left="download"
                        @click="runExport">
                        Excel export
                    </b-button>
                </div>
            </div>
        </div>
    </div>

    <b-loading
        v-model="loading"
        is-full-page />

    <b-notification
        v-if="errorMessage"
        type="is-danger"
        has-icon
        role="alert">
        <p>{{ errorMessage }}</p>
    </b-notification>

    <div
        v-show="displayQuery"
        class="block">
        <pre>{{ query }}</pre>
    </div>

    <div
        v-if="result"
        class="query-results">
        <b-table
            striped
            narrowed
            paginated
            :per-page="pageSize"
            :data="result">
            <template
                v-for="column in resultColumns"
                :key="column.field">
                <b-table-column
                    v-slot="props"
                    :field="column.field"
                    :sortable="column.sortable"
                    :searchable="true"
                    :label="column.label">
                    <template v-if="column.field === 'Id' || column.field.endsWith('_ID') && props.row[column.field]">
                        <router-link :to="codeLinkFromDbId(props.row[column.field])">
                            {{ props.row[column.field] }}
                        </router-link>
                    </template>
                    <template v-else>
                        <span
                            class="keep-spaces"
                            v-text="props.row[column.field]" />
                    </template>
                </b-table-column>
            </template>
            <template #empty>
                <div class="has-text-left">
                    No records
                </div>
            </template>
        </b-table>
    </div>
</template>

<style>
.query-results {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  padding: 4px;
}
.keep-spaces {
    white-space: pre;
}
</style>

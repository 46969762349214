<template>
    <div>
        <div
            v-show="!selectedLibrary"
            class="block">
            Select a library to get example queries and information about available data columns.
        </div>
        <div class="block">
            <b-field
                label="Select library:">
                <b-autocomplete
                    v-model="input"
                    :data="filteredList"
                    placeholder="Library name"
                    :autofocus="true"
                    :keep-first="true"
                    :open-on-focus="true"
                    icon="magnify"
                    :clearable="true"
                    @select="option => selectedLibrary = option">
                    <template #empty>
                        No library found
                    </template>
                </b-autocomplete>
            </b-field>
            <router-link
                v-if="selectedLibrary"
                :to="getLibraryLink(selectedLibrary)">
                Go to {{ selectedLibrary }} library page &raquo;
            </router-link>
        </div>
        <div
            v-if="exampleQueryGenerators"
            class="block">
            <b-field label="Example queries:">
                <b-button
                    v-for="example in exampleQueryGenerators"
                    :key="example.description"
                    type="is-info"
                    size="is-small"
                    icon-left="script-text-outline"
                    expanded
                    class="mb-1"
                    @click="loadExample(example)">
                    <span>{{ example.description }}</span>
                </b-button>
            </b-field>
        </div>
        <div
            v-if="viewDefinition"
            class="block">
            <b-field label="Table definition:">
                <b-table
                    :striped="true"
                    :narrowed="true"
                    :mobile-cards="false"
                    :data="viewDefinition.columns">
                    <b-table-column
                        v-slot="props"
                        width="10">
                        <b-tooltip
                            v-if="props.row.icon"
                            :label="props.row.icon_tooltip"
                            position="is-right">
                            <b-icon
                                :icon="props.row.icon"
                                size="is-small" />
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="column_name"
                        label="Column">
                        {{ props.row.column_name }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="data_type_full"
                        label="Type">
                        {{ props.row.data_type_full }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="is_nullable"
                        label="Nullable">
                        {{ props.row.is_nullable }}
                    </b-table-column>
                </b-table>
            </b-field>
        </div>
    </div>
</template>

<script>
    import { genericViewDefinition, getAttributeDefinitions } from '@/shared/helpers/api';
    import { getLibraryLink } from '@/shared/helpers/routing';
    import { getExamples } from './sqlExampleGenerator.ts';

    export default {
        name: 'SqlLibraryBrowser',
        props: {
            libraryList: {
                type: Array,
                required: true
            }
        },
        emits: [
            'selected',
            'set-sql'
        ],
        data() {
            return {
                input: '',
                selectedLibrary: null,
                viewDefinition: null,
                attributeDefinitions: null,
                exampleQueryGenerators: null
            };
        },
        computed: {
            filteredList() {
                if (!this.input)
                    return this.libraryList;

                return this.libraryList.filter((option) =>
                    option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.input.toLowerCase()) >= 0
                );
            }
        },
        watch: {
            async selectedLibrary() {
                const lib = this.selectedLibrary;
                if (lib) {
                    const [
                        viewDefinitionResult,
                        attributeDefinitionsResult
                    ] = await Promise.all([
                        genericViewDefinition(this, lib),
                        getAttributeDefinitions(this, lib)
                    ]);

                    // NB: attributeDefinitions must be set before enhanceColumnDefinitions is called
                    this.attributeDefinitions = attributeDefinitionsResult;

                    this.enhanceColumnDefinitions(viewDefinitionResult);
                    this.viewDefinition = viewDefinitionResult;

                    this.$emit('selected', lib);

                    this.exampleQueryGenerators = getExamples(this.viewDefinition, this.attributeDefinitions);
                } else {
                    this.viewDefinition = null;
                    this.attributeDefinitions = null;
                    this.exampleQueryGenerators = null;
                }
            }
        },
        methods: {
            loadExample(example) {
                const sql = example.makeQuery(this.viewDefinition, this.attributeDefinitions);
                this.$emit('set-sql', sql);
            },
            enhanceColumnDefinitions(tableDefinition) {
                const getFullType = (c) => {
                    if (c.data_type === 'nvarchar') {
                        if (c.character_maximum_length === -1) {
                            return 'nvarchar(max)';
                        }
                        return `nvarchar(${c.character_maximum_length})`;
                    }
                    return c.data_type;
                };

                tableDefinition.columns.forEach(c => {
                    c.data_type_full = getFullType(c);

                    if (c.column_name === 'Id') {
                        c.icon = 'key';
                        c.icon_tooltip = 'Primary key';
                    } else if (c.column_name === 'Scope') {
                        c.icon = 'group';
                        c.icon_tooltip = 'Scope is used to group, and Identity is unique in a Scope.';
                    } else if (c.column_name.endsWith('_ID')) {
                        const attributeDefinition = this.attributeDefinitions.find(x => x.name === c.column_name.slice(0, -3));
                        c.icon = 'key-link';
                        c.icon_tooltip = `Foreign key to ${attributeDefinition.referenceLibraryName}`;
                    }
                });
            },
            getLibraryLink
        }
    };
</script>

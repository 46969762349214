<template>
    <div>
        <div
            v-if="selectedScope"
            class="columns">
            <project-list
                v-model:selected-project="selectedProject"
                :projects="projects"
                :selected-scope="selectedScope"
                :loading="projectsLoading" />
            <div
                class="column is-half">
                <div
                    class="is-flex is-gap-10 mb-3">
                    <existing-project-search
                        @selected="onExistingProjectSelected" />
                    <b-button
                        v-require-can-edit-code="accessArguments"
                        :model-value="isEditing"
                        type="is-primary"
                        @click="onNewProjectClicked">
                        <b-icon
                            icon="plus"
                            size="is-small" />
                        <span>Create new project</span>
                    </b-button>
                </div>
                <section
                    v-if="selectedProject && !editFormActive && !editProjectFacilityActive">
                    <project-attributes-panel
                        :selected-project-attributes="selectedProjectAttributes"
                        :access-arguments="accessArguments"
                        @click-edit-project="editFormActive = true" />
                    <facility-associations-panel
                        v-model:selected-project-facility="selectedProjectFacility"
                        :project-facility-loading="projectFacilityLoading"
                        :selected-project-facilities="selectedProjectFacilities"
                        :access-arguments="accessArguments"
                        @click-edit-associations="editProjectFacilityActive = true"
                        @click-new-association="onNewProjectFacilityClicked" />
                </section>
                <spinner
                    v-if="loading"
                    :loading="loading" />
                <div
                    v-if="editFormActive"
                    class="column is-one third">
                    <code-edit
                        :key="selectedProjectId"
                        :show-cancel="true"
                        :code-id="selectedProjectId"
                        :form-title="codeEditTitle"
                        :code-set-name="codeSetNameProject"
                        :release-chain-factory="newProjectReleaseChainFactory"
                        :fields-to-ignore="['ConstructionKPIStartDate', 'TargetFactorProductivity', 'TargetFactorInstallation']"
                        library="Project"
                        :scopes="selectedScope"
                        form-title-class="is-4"
                        submit-button-label="Save"
                        @refresh="afterProjectSave"
                        @cancel="editFormActive = false">
                        <template #notification="props">
                            <b-notification
                                v-show="props.isIdentityConflict"
                                type="is-danger is-light"
                                :closable="false"
                                has-icon
                                role="alert">
                                <p>
                                    <b>{{ getNotificationMessage(props.identityConflictName, props.id) }} </b>
                                </p><br>
                                <b-button
                                    class="is-primary"
                                    @click="onClickButtonInNotification(props.id)">
                                    <b-icon
                                        icon="link-variant"
                                        size="is-small" />
                                    <span>{{ getButtonText(props.id) }}</span>
                                </b-button>
                            </b-notification>
                        </template>
                    </code-edit>
                </div>
                <div
                    v-else-if="editProjectFacilityActive"
                    class="column is-one third">
                    <code-edit
                        :key="selectedProjectFacilityId"
                        library="ProjectFacility"
                        :scopes="selectedScope"
                        :show-cancel="true"
                        :form-title="projectFacilityCodeEditTitle"
                        :code-id="selectedProjectFacilityId"
                        :code-set-name="codeSetNameProjectFacility"
                        :code-template-values="getCodeTemplateValues"
                        :fields-to-disable="[ 'Project', 'Facility' ]"
                        :referable-codes-not-to-load="[ 'Project', 'Facility' ]"
                        :fields-to-ignore="[ 'name' ]"
                        form-title-class="is-4"
                        submit-button-label="Save"
                        @refresh="afterProjectFacilitySave"
                        @cancel="editProjectFacilityActive = false" />
                </div>
            </div>
        </div>
        <b-message v-else>
            Please select a scope.
        </b-message>
    </div>
</template>

<script>
    import ExistingProjectSearch from '@/apps/project/components/ExistingProjectSearch.vue';
    import FacilityAssociationsPanel from '@/apps/project/components/FacilityAssociationsPanel.vue';
    import ProjectAttributesPanel from '@/apps/project/components/ProjectAttributesPanel.vue';
    import ProjectList from '@/apps/project/components/ProjectList.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { assert } from '@/shared/helpers/assert.ts';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText, getCodeSets, sqlQuery } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import Spinner from '@/shared/components/Spinner.vue';
    import { http } from '@/shared/httpWrapper.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: { Spinner, ExistingProjectSearch, FacilityAssociationsPanel, ProjectAttributesPanel, ProjectList, CodeEdit },
        mixins: [
            showMixin
        ],
        props: {
            selectedScope: {
                type: String,
                required: false,
                default: () => null
            },
            navigateToProject: {
                type: String,
                required: false,
                default: () => null
            }
        },
        emits: [
            'updateProject'
        ],
        data() {
            return {
                projects: [],
                projectsLoading: false,
                selectedProject: null,
                editFormActive: false,
                codeSetNameProject: '',
                codeSetNameProjectFacility: '',
                projectFacilityLoading: false,
                selectedProjectFacilities: [],
                editProjectFacilityActive: false,
                selectedProjectFacility: null,
                existingProjectToLinkWithFacility: null,
                loading: false
            };
        },
        computed: {
            accessArguments() {
                // Note: Using ProjectFacility (not Project) as adding a new Project will also add a ProjectFacility,
                // and Project is originally derived from ProjectFacility. ProjectFacility will generally have
                // stronger access restrictions than Project.
                return {
                    libraryName: 'ProjectFacility',
                    scopes: [this.selectedScope]
                };
            },
            selectedProjectId() {
                return this.selectedProject ? encodeIdBase64('Code', this.selectedProject.Id) : null;
            },
            selectedProjectFacilityId() {
                return this.selectedProjectFacility ? encodeIdBase64('Code', this.selectedProjectFacility.id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return verb + ' Project';
            },
            isEditing() {
                return this.editFormActive && !!this.selectedProjectId;
            },
            isEditingProjectFacility() {
                return this.editProjectFacilityActive && !!this.selectedProjectFacility;
            },
            projectFacilityCodeEditTitle() {
                const verb = this.isEditingProjectFacility ? 'Edit' : 'New';
                return verb + ' Facility association';
            },
            getCodeTemplateValues() {
                return {
                    name: this.selectedProject?.Name ?? this.existingProjectToLinkWithFacility?.Name,
                    Project: this.selectedProject?.Name ?? this.existingProjectToLinkWithFacility?.Name,
                    description: this.selectedProject?.Description ?? this.existingProjectToLinkWithFacility?.Description,
                    Facility: this.selectedScope
                };
            },
            selectedProjectAttributes() {
                return Object.keys(this.selectedProject)
                    .filter(key =>
                        key !== 'Id' && key !== 'ProjectFacilityDescription' && key !== 'ProjectFacilityIsValid'
                        && this.selectedProject[key] !== null
                        && this.selectedProject[key] !== '')
                    .map(key => ({
                        property: key,
                        value: this.selectedProject[key]
                    }));
            },
            newProjectReleaseChainFactory() {
                if (this.isEditing)
                    return null;

                const self = this;
                return function(chain) {
                    const project = chain[0].codes[0];
                    chain.push({
                        codeSetName: self.codeSetNameProjectFacility,
                        libraryName: 'ProjectFacility',
                        codes: [
                            {
                                Name: project.Name,
                                Description: project.Description,
                                IsValid: true,
                                Project: project.Name,
                                Facility: self.selectedScope
                            }
                        ]
                    });
                    return chain;
                };
            }
        },
        watch: {
            selectedScope: {
                async handler(newValue) {
                    if (newValue) {
                        await this.loadProjects();
                    } else {
                        this.projects = [];
                    }
                },
                immediate: true
            },
            selectedProject: {
                handler(newValue, oldValue) {
                    if (!oldValue) {
                        this.editFormActive = false;
                    }
                    this.editProjectFacilityActive = false;
                    this.loadProjectFacility();
                    if (this.selectedProject) {
                        this.$emit('updateProject', this.selectedProject.Name);
                    }
                },
                immediate: true
            }
        },
        async mounted() {
            this.codeSetNameProject = await this.fetchCodeSetName('Project');
            this.codeSetNameProjectFacility = await this.fetchCodeSetName('ProjectFacility');
        },
        methods: {
            async loadProjects() {
                this.resetUI();
                this.projectsLoading = true;
                const res = await genericViewQueryAsText(
                    this,
                    `FROM ProjectFacility
                        Select description as ProjectFacilityDescription, isValid as ProjectFacilityIsValid
                        WHERE Facility = @facility
                        JOIN Project
                          SELECT Id, Identity, Name, Description, ProjectMaster, IsValid, InternalComment, IsClosed
                          JOIN ProjectMaster
                            SELECT Name AS ProjectMasterName
                          END
                        END
                        ORDER BY Name`,
                    [{ name: '@facility', value: `"${this.selectedScope}"` }]
                );
                this.projects = res.data;
                this.openProject(this.navigateToProject);
                this.projectsLoading = false;
            },
            openProject(project) {
                if (project) {
                    this.selectedProject = this.projects.find(x => x.Name === project);
                }
            },
            resetUI() {
                this.selectedProject = null;
                this.editFormActive = false;
                this.projectFacilityLoading = false;
            },
            onNewProjectClicked() {
                this.editFormActive = true;
                this.selectedProject = null;
            },
            async fetchCodeSetName(libraryName) {
                const res = await getCodeSets(this, libraryName);
                return res.length === 1 ? res[0].name : null;
            },
            async fetchProjectFacilities(projectId, facility) {
                try {
                    const query = `SELECT pf.id
                                        , pf.facility
                                        , pf.deliveryCode
                                        , pf.description  as projectFacilityDescription
                                        , ldc.description as deliveryCodeDescription
                                   FROM ProjectFacility pf
                                            JOIN LCIDeliveryCode ldc on pf.deliveryCode_id = ldc.id
                                   WHERE pf.project_id = ${projectId} ${facility ? `AND pf.facility = '${facility}'` : ''}
                                   ORDER BY pf.facility`;
                    const res = await sqlQuery(http, { query });
                    return res.data;
                } catch (ex) {
                    this.showError(ex);
                }
            },
            async loadProjectFacility() {
                if (!this.selectedProject)
                    return;
                this.projectFacilityLoading = true;
                this.selectedProjectFacilities = [];
                this.selectedProjectFacilities = await this.fetchProjectFacilities(this.selectedProject.Id);
                this.projectFacilityLoading = false;
            },
            onNewProjectFacilityClicked() {
                this.editProjectFacilityActive = true;
                this.selectedProjectFacility = null;
            },
            async afterProjectSave() {
                this.editFormActive = false;
                await this.loadProjects();
            },
            async afterProjectFacilitySave() {
                this.editProjectFacilityActive = false;
                await this.loadProjects();
                if (this.existingProjectToLinkWithFacility) {
                    this.selectedProject = this.projects.find(project => project.Name === this.existingProjectToLinkWithFacility.Name);
                }
            },
            async onExistingProjectSelected(option) {
                this.selectedProject = await this.getExistingProjectDetails(option.id);
            },
            isProjectAssociatedWithFacility(id) {
                return this.projects.find(x => x.Id === id);
            },
            getNotificationMessage(identityConflictName, id) {
                return `Project ${identityConflictName} already exists`
                    + (this.isProjectAssociatedWithFacility(id)
                        ? ` and is associated with facility ${this.selectedScope}`
                        : `. Click below to link this project to facility ${this.selectedScope}`);
            },
            getButtonText(id) {
                return this.isProjectAssociatedWithFacility(id)
                    ? `Update existing association`
                    : 'Link existing project to facility';
            },
            async onClickButtonInNotification(id) {
                const projectAssociatedWithFacility = this.isProjectAssociatedWithFacility(id);

                this.existingProjectToLinkWithFacility = projectAssociatedWithFacility || await this.getExistingProjectDetails(id);

                // If the project is already associated with the facility, we need to load the ProjectFacility association in order to edit it.
                if (projectAssociatedWithFacility) {
                    this.loading = true;
                    const res = await this.fetchProjectFacilities(id, this.selectedScope);
                    this.loading = false;
                    this.selectedProjectFacility = res[0];
                } else {
                    this.existingProjectToLinkWithFacility = await this.getExistingProjectDetails(id);
                    this.selectedProjectFacility = null;
                }

                this.editFormActive = false;
                this.editProjectFacilityActive = true;
            },
            async getExistingProjectDetails(id) {
                const row = await genericViewQueryAsText(
                    this,
                    `TAKE 1
            SELECT Id, Name, Description, ProjectMaster, IsValid, InternalComment
                 FROM Project
                 WHERE Id = @id
                 JOIN ProjectMaster
                    SELECT Name AS ProjectMasterName
                  END
                END`,
                    [{ name: '@id', value: id }]
                ).then(response => response.data[0]);
                assert(row, 'Query should always succeed');
                // Using TAKE adds a _row property to the object
                delete row._row;
                return row;
            }
        }
    };
</script>

<style scoped>
.headless-table thead {
    display: none;
}
</style>

/*
    This is a fluent builder DSL to construct formatted SQL queries.
    It's built to create simple queries meant as example queries,
    and does not focus on feature completeness or input safety.
    Extend the builder only when it's needed.

    See tests for usage.
*/

const sqlBuilder = {
    select: function(columns, options) {
        const top = options?.top ? `TOP (${options.top}) ` : '';
        this.query += `${this.indent}SELECT ${top + columns.join(`\n${this.indent}    , `)}\n`;
        return this;
    },
    from: function(table) {
        this.query += `${this.indent}  FROM ${table}\n`;
        return this;
    },
    join: function(expression, options) {
        const joinType = options?.type ? options.type.toUpperCase() + ' ' : '';
        this.query += `${this.indent}  ${joinType}JOIN ${expression}\n`;
        return this;
    },
    where: function(condition) {
        this.query += `${this.indent}  WHERE ${condition}\n`;
        return this;
    },
    and: function(condition) {
        this.query += `${this.indent}    AND ${condition}\n`;
        return this;
    },
    groupBy: function(condition) {
        this.query += `${this.indent}  GROUP BY ${condition.join(', ')}\n`;
        return this;
    },
    orderBy: function(terms) {
        this.query += `${this.indent}  ORDER BY ${terms.join(', ')}\n`;
        return this;
    },
    asSubQuery: function(alias) {
        return `(\n${this.build()}) ${alias}`;
    },
    build: function() {
        return this.query.trimEnd();
    }
};

export function makeSql(options) {
    const indent = options?.indent ?? '';
    const builder = Object.create(sqlBuilder, {
        query: {
            value: options?.comment ? `${indent}-- ${options.comment}\n` : '',
            writable: true
        },
        indent: {
            value: indent,
            writable: false
        }
    });
    return builder;
};

<template>
    <div
        class="modal-card"
        style="height: auto; width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Edit Library Set
            </p>
        </header>
        <section class="modal-card-body">
            <div style="height: auto; width: auto">
                <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                        <input
                            v-model="newDescription"
                            class="input"
                            type="text">
                    </div>
                </div>
                <div
                    v-if="canEditRegex"
                    class="field">
                    <label class="label">Regex</label>
                    <div class="control">
                        <input
                            v-model="newRegex"
                            class="input"
                            type="text">
                    </div>
                </div>
                <div
                    v-show="!library.isGlobal"
                    class="field"
                    style="margin-bottom: 1em">
                    <label class="label">Scope</label>
                    <div
                        class="control"
                        style="margin-bottom: 1em">
                        Scopes linked to this library set. &nbsp;&nbsp;&nbsp;
                        <div class="select">
                            <b-select
                                v-model="selectedScope"
                                :required="true"
                                placeholder="Select your option">
                                <option
                                    v-for="scope in scopesNotSelected"
                                    :key="scope"
                                    :value="scope">
                                    {{ scope }}
                                </option>
                            </b-select>
                        </div>
                        <button
                            v-require-can-edit-library="library.name"
                            class="button is-info"
                            @click="linkScope(selectedScope)">
                            <i class="fa fa-plus" />&nbsp;Add
                        </button>
                    </div>

                    <b-loading
                        :is-full-page="false"
                        :model-value="loading"
                        :can-cancel="false" />
                    <div class="field is-grouped is-grouped-multiline">
                        <div
                            v-for="(scope, idx) in scopeViewModel"
                            :key="idx"
                            class="control">
                            <div
                                v-if="scope.changeType == 'New'"
                                class="tags has-addons">
                                <span class="tag is-medium is-dark">
                                    <i class="fa fa-plus" />&nbsp;{{ scope.name }}
                                </span>
                                <a
                                    class="tag is-medium is-primary"
                                    @click="unlinkScope(scope.name)">
                                    <i class="fa fa-trash" />
                                </a>
                            </div>

                            <div
                                v-else-if="scope.changeType == 'Removed'"
                                class="tags has-addons">
                                <span class="tag is-medium is-dark">
                                    <i class="fa fa-minus" />&nbsp;<span class="is-strikeout">{{
                                        scope.name
                                    }}</span>
                                </span>
                                <a
                                    class="tag is-medium is-info"
                                    @click="cancelUnlinkScope(scope.name)">
                                    <i class="fa fa-eraser" />
                                </a>
                            </div>

                            <div
                                v-else
                                class="tags has-addons">
                                <span class="tag is-medium is-dark">{{ scope.name }}</span>
                                <a
                                    v-require-can-edit-library="library.name"
                                    class="tag is-medium is-primary"
                                    @click="unlinkScope(scope.name)">
                                    <i class="fa fa-trash" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Settings</label>
                    <b-checkbox v-model="newLockedForDelete">
                        Locked: Item delete not allowed
                    </b-checkbox><br>
                    <b-checkbox v-model="newIsReleaseReady">
                        Ready for release
                    </b-checkbox>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button
                :class="{ 'is-loading': saveLoading, 'is-primary': true, button: true }"
                :disabled="loading"
                @click="editDone">
                Update Library Set
            </button>
            <button
                class="button"
                @click="closeModal">
                Cancel
            </button>
        </footer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { getReleaseLink } from '@/shared/helpers/routing.js';
    import { createScopeCodeSetRelease, getScopes, updateCodeSet } from '@/shared/helpers/api.ts';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import { compareByProperty } from '@/shared/helpers/utils.js';
    import { requireCanEditLibrary } from '@/shared/directives/requirePermission.js';

    export default {
        directives: {
            'require-can-edit-library': requireCanEditLibrary
        },
        mixins: [showMixin],
        props: {
            codeset: {
                type: Object,
                required: true
            },
            library: {
                type: Object,
                required: true
            },
            scopes: {
                type: Array,
                required: true
            }
        },
        emits: [
            'refresh',
            'close'
        ],
        data() {
            return {
                allScopes: [],
                scopeCodeSetChanges: [],
                selectedScope: null,
                newDescription: null,
                newRegex: null,
                newLockedForDelete: null,
                newIsReleaseReady: null,
                loading: true,
                saveLoading: false
            };
        },
        computed: {
            scopeViewModel() {
                const scopesNotChanged = this.scopes
                    .map((scope) => ({ name: scope, changeType: undefined }))
                    .filter(
                        (scope) =>
                            !this.scopeCodeSetChanges
                                .map((c) => c.scopeName)
                                .includes(scope.name)
                    );
                return this.scopeCodeSetChanges
                    .map((scope) => ({
                        name: scope.scopeName,
                        changeType: scope.changeType
                    }))
                    .concat(scopesNotChanged)
                    .sort(compareByProperty('name'));
            },
            scopesNotSelected() {
                const selectedScopes = this.scopeViewModel.map(x => x.name);
                return this.allScopes.filter(scopeName => !selectedScopes.includes(scopeName));
            },
            canEditRegex() {
                return (
                    this.library.isGlobal
                    || !this.library.isGlobal && this.scopes.length > 0
                );
            }
        },
        async mounted() {
            if (!this.library.isGlobal)
                this.allScopes = await getScopes(this, this.library.scopeType).then(
                    (vals) => vals.sort()
                );
            this.newDescription = this.codeset.description;
            this.newRegex = this.codeset.codeNameRegex;
            this.newLockedForDelete = this.codeset.lockedForDelete;
            this.newIsReleaseReady = this.codeset.isReleaseReady;
            this.loading = false;
        },
        methods: {
            unlinkScope(scopeName) {
                if (this.scopes.map((s) => s).includes(scopeName)) {
                    this.scopeCodeSetChanges.push({
                        scopeName: scopeName,
                        changeType: 'Removed'
                    });
                } else {
                    const index = _.findIndex(this.scopeCodeSetChanges, {
                        scopeName: scopeName,
                        changeType: 'New'
                    });
                    this.scopeCodeSetChanges.splice(index, 1);
                }
            },
            cancelUnlinkScope(scopeName) {
                const index = _.findIndex(this.scopeCodeSetChanges, {
                    scopeName: scopeName,
                    changeType: 'Removed'
                });
                this.scopeCodeSetChanges.splice(index, 1);
            },
            linkScope(scopeName) {
                if (scopeName === null) return;
                if (this.scopeCodeSetChanges.map((s) => s.scopeName).includes(scopeName))
                    return;
                if (this.scopes.map((s) => s.name).includes(scopeName)) return;

                this.scopeCodeSetChanges.push({
                    scopeName: scopeName,
                    changeType: 'New'
                });
            },
            async saveScopeCodeSetChanges() {
                const self = this;
                const request = {
                    codeSetName: self.codeset.name,
                    changes: self.scopeCodeSetChanges,
                    codeSetDescription: self.codeset.description
                };
                const response = await createScopeCodeSetRelease(this, request);

                if (response) {
                    await this.$router.push(getReleaseLink(response.data.id));
                }

                return true;
            },
            async updateCodeSet() {
                try {
                    this.saveLoading = true;
                    await updateCodeSet(this, this.library.name, this.codeset.name, {
                        description: this.newDescription,
                        codeNameRegex: this.newRegex,
                        lockedForDelete: this.newLockedForDelete,
                        isReleaseReady: this.newIsReleaseReady,
                        isValid: this.codeset.isValid
                    });

                    this.saveLoading = false;
                    return true;
                } catch (err) {
                    this.showError(
                        _.get(err, 'response.data.Message', 'unable to update codeset')
                    );
                    // Sleep 4 sec to delay page reload's so that the user can read error message
                    await new Promise((r) => setTimeout(r, 4000));
                    this.saveLoading = false;
                    return false;
                }
            },
            async editDone() {
                const codeSetEdited
                    = this.newDescription !== this.codeset.description
                        || this.newLockedForDelete !== this.codeset.lockedForDelete
                        || this.newIsReleaseReady !== this.codeset.isReleaseReady
                        || this.newRegex !== this.codeset.codeNameRegex;

                const scopingIsEdited
                    = !this.library.isGlobal && this.scopeCodeSetChanges.length > 0;

                if (scopingIsEdited) {
                    const scopesLen = this.scopes.length;
                    const changedScopesLen = this.scopeCodeSetChanges.length;
                    const removedScopesLen = this.scopeCodeSetChanges.filter(
                        (x) => x.changeType === 'Removed'
                    ).length;

                    if (scopesLen === removedScopesLen && removedScopesLen === changedScopesLen) {
                        this.showError('Can\'t remove all scopes!');
                        return;
                    }
                }

                let codeSetUpdated = false;

                if (codeSetEdited) {
                    codeSetUpdated = await this.updateCodeSet();
                }

                if (scopingIsEdited) {
                    await this.saveScopeCodeSetChanges();
                    this.closeModal();
                }

                if (codeSetUpdated && !scopingIsEdited) {
                    this.$emit('refresh');
                    this.closeModal();
                }
            },
            closeModal() {
                this.$emit('close');
            },
            getReleaseLink
        }
    };
</script>

<style scoped>
 a[disabled]{
     opacity: 0.6;
 }
</style>

import { AttributeDefinition } from '@/shared/helpers/api/types/entities.ts';
import { ViewDefinitionDTO } from '@/shared/helpers/api.ts';
import { makeSql } from '@/shared/helpers/sqlBuilder.js';

const isScoped = (viewDefinition: ViewDefinitionDTO) => {
    return viewDefinition.columns.some(c => c.column_name === 'Scope');
};

const mapColumns = (viewDefinition: ViewDefinitionDTO) => {
    return viewDefinition.columns.map(c => `[${c.column_name}]`);
};

const isCodeRef = (attributeDefinition: AttributeDefinition) => {
    return attributeDefinition.attributeType === 'CodeRef';
};

const hasColumn = (column: string, viewDefinition: ViewDefinitionDTO) => {
    return viewDefinition.columns.some(x => x.column_name === column);
};

// 0 -> 'b', 1 -> 'c', 2 -> 'd', etc.
const getAliasFromIndex = (index: number): string => {
    const firstAliasCharCode = 'b'.charCodeAt(0);
    return String.fromCharCode(firstAliasCharCode + index);
};

type QueryMakerFunction = (
    viewDefinition: ViewDefinitionDTO,
    attributeDefinitions: AttributeDefinition[]
) => string;

type SqlExampleQueryInfo = {
    makeQuery: QueryMakerFunction;
    description: string;
};

export function makeSimpleQuery(viewDefinition: ViewDefinitionDTO): string {
    const excludeColumns = new Set([
        '[IsValid]',
        '[AttachmentKey]',
        '[CommonLibraryIRI]',
        '[IsReleaseReady]'
    ]);
    const sqlBuilder = makeSql({ comment: 'Example query: Top 1000 valid items' })
        .select(mapColumns(viewDefinition).filter(c => !excludeColumns.has(c)), { top: 1000 })
        .from(`[${viewDefinition.library}]`)
        .where('IsValid = 1');

    if (hasColumn('IsReleaseReady', viewDefinition)) {
        sqlBuilder.and('IsReleaseReady = 1');
    }

    return sqlBuilder.build();
};

export function makeAggregatedQuery(viewDefinition: ViewDefinitionDTO): string {
    if (isScoped(viewDefinition)) {
        return makeSql({ comment: 'Example query: Item counts (valid/invalid) grouped by Scope' })
            .select([
                'Scope',
                'SUM(ValidCount) AS ValidCount',
                'SUM(InvalidCount) AS InvalidCount'
            ])
            .from(
                makeSql({ indent: '    ' })
                    .select([
                        'Scope',
                        'CASE WHEN IsValid = 1 THEN 1 ELSE 0 END AS ValidCount',
                        'CASE WHEN IsValid = 0 THEN 1 ELSE 0 END AS InvalidCount'
                    ])
                    .from(`[${viewDefinition.library}]`)
                    .asSubQuery('g')
            )
            .groupBy([
                'Scope'
            ])
            .orderBy([
                'ValidCount DESC',
                'InvalidCount DESC'
            ])
            .build();
    } else {
        return makeSql({ comment: 'Example query: Item count grouped by IsValid' })
            .select([
                'IsValid',
                'COUNT(1) AS Count'
            ])
            .from(`[${viewDefinition.library}]`)
            .groupBy([
                'IsValid'
            ])
            .build();
    }
};

export function makeJoinedQuery(viewDefinition: ViewDefinitionDTO, attributeDefinitions: AttributeDefinition[]): string {
    const codeRefs = attributeDefinitions.filter(isCodeRef);

    if (codeRefs.length > 0) {
        const excludeColumns = new Set([
            '[Identity]',
            '[IsValid]',
            '[DateCreated]',
            '[DateUpdated]',
            '[AttachmentKey]',
            '[CommonLibraryIRI]',
            '[IsReleaseReady]'
        ]);

        const columns = mapColumns(viewDefinition)
            .filter(c => !excludeColumns.has(c))
            .map(x => `a.${x}`);

        codeRefs.forEach((ref, index) => {
            const refAlias = getAliasFromIndex(index);
            const indexOfRefColumn = columns.indexOf(`a.[${ref.name}]`);
            columns.splice(indexOfRefColumn + 1, 0, `${refAlias}.[Description] AS ${ref.name}_Description`);
        });

        const sqlBuilder = makeSql({ comment: 'Example query: Join referenced libraries' })
            .select(columns, { top: 1000 })
            .from(`[${viewDefinition.library}] a`);

        codeRefs.forEach((ref, index) => {
            const refAlias = getAliasFromIndex(index);
            sqlBuilder.join(`[${ref.referenceLibraryName}] ${refAlias} ON ${refAlias}.Id = a.${ref.name}_ID`, {
                type: 'left'
            });
        });

        sqlBuilder.where('a.IsValid = 1');

        if (hasColumn('IsReleaseReady', viewDefinition)) {
            sqlBuilder.and('a.IsReleaseReady = 1');
        }

        return sqlBuilder.build();
    }

    return makeSql({
        comment: `Joined example not available for ${viewDefinition.library}`
    }).build();
};

export function getExamples(viewDefinition: ViewDefinitionDTO, attributeDefinitions: AttributeDefinition[]) {
    const result: SqlExampleQueryInfo[] = [
        {
            description: 'Top 1000 valid rows',
            makeQuery: makeSimpleQuery
        },
        {
            description: isScoped(viewDefinition) ? 'Group by Scope' : 'Group by IsValid',
            makeQuery: makeAggregatedQuery
        }
    ];

    if (attributeDefinitions.some(isCodeRef)) {
        result.push({
            description: 'Join referenced libraries',
            makeQuery: makeJoinedQuery
        });
    }

    return result;
};

<template>
    <spinner :loading="loading">
        <form
            v-if="!loading"
            :class="useBoxRender ? 'box' : ''">
            <h2
                v-if="formTitle"
                :class="titleClass">
                {{ formTitle }}
            </h2>
            <slot
                :id="identityConflictId"
                :identity-conflict-name="identityConflictName"
                :is-identity-conflict="isIdentityConflict"
                name="notification">
                <b-notification
                    v-show="isIdentityConflict"
                    type="is-danger is-light"
                    :closable="false"
                    has-icon
                    role="alert">
                    <p><b>This item already exists!</b></p>
                    <p>
                        {{ identityConflictMessage }}<br>
                        <a :href="getCodeLink(codeSetName, identityConflictName)">Navigate to existing item </a>
                    </p>
                </b-notification>
            </slot>
            <div :class="useColumns ? 'columns-wrapper' : null">
                <div
                    v-for="attr in attributes"
                    :key="attr.field">
                    <div
                        v-if="!isVisible(attr) && !fieldsToIgnore.includes(attr.field) && !isSvgAttributeType(attr.type)"
                        class="field is-horizontal">
                        <label class="field-label label pad-top">
                            <span>{{ attr.label === 'Name' ? getNameLabel(nameAlias): attr.label }}:</span>
                            <b-tooltip
                                label="Required"
                                position="is-top">
                                <span class="has-text-danger">{{ attr.required ? '*' : ' ' }}</span>
                            </b-tooltip>
                        </label>
                        <div :class="(useColumns ? 'field-body-column' : 'field-body') + ' has-margin-top field is-grouped has-addons'">
                            <div class="control is-expanded">
                                <bool-selector
                                    v-if="attr.type === 'Bool' || attr.type === 'BOOL'"
                                    :disabled="isSelectorDisabled(attr)"
                                    :value="''+localCode.data[attr.field].value"
                                    @bool-select="localCode.data[attr.field].value = $event" />

                                <template
                                    v-else-if="attr.type === 'CodeRef' || attr.type === 'CODEREF'">
                                    <span v-if="isSelectorReferenceLoadingIgnored(attr)">{{ localCode.data[attr.field].value }}</span>
                                    <code-ref-selector
                                        v-else
                                        :disabled="isSelectorDisabled(attr)"
                                        :selected="localCode.data[attr.field].value"
                                        :code-refs="referableCodesFiltered[attr.field]"
                                        @ref-selected="localCode.data[attr.field].value = $event" />
                                </template>

                                <library-selector
                                    v-else-if="attr.type === 'LibraryRef' || attr.type === 'LIBRARYREF'"
                                    :disabled="isSelectorDisabled(attr)"
                                    :selected="localCode.data[attr.field].value"
                                    @lib-selected="localCode.data[attr.field].value = $event" />

                                <date-time-picker
                                    v-else-if="attr.type === 'Date' || attr.type === 'DATE'"
                                    :disabled="isSelectorDisabled(attr)"
                                    :input="localCode.data[attr.field].value"
                                    :date-only="true"
                                    @dt-picker="localCode.data[attr.field].value = $event" />

                                <date-time-picker
                                    v-else-if="attr.type === 'DateTime' || attr.type === 'DATETIME'"
                                    :disabled="isSelectorDisabled(attr)"
                                    :input="localCode.data[attr.field].value"
                                    :date-only="false"
                                    @dt-picker="localCode.data[attr.field].value = $event" />

                                <b-field
                                    v-else-if="attr.type === 'String' || attr.type === 'STRING'"
                                    :type="getFieldType(attr, localCode.data[attr.field].value, code.data[attr.field].value)"
                                    :message="invalidInputError(attr)">
                                    <b-input
                                        v-model="localCode.data[attr.field].value"
                                        :disabled="isSelectorDisabled(attr)"
                                        :required="attr.required"
                                        :maxlength="showMaxLength(attr)"
                                        :type="attributeTypeToInputType(attr.type)" />
                                </b-field>

                                <div v-else-if="isSvgAttributeType(attr.type)" />

                                <b-input
                                    v-else
                                    v-model="localCode.data[attr.field].value"
                                    :disabled="isSelectorDisabled(attr)"
                                    :required="attr.required"
                                    :type="attributeTypeToInputType(attr.type)"
                                    :step="attributeTypeToStepSize(attr.type)" />
                            </div>
                            <b-tooltip
                                class="control"
                                label="Clear field"
                                :class="{'hide': attr.includeInIdentity || attr.required || fieldsToDisable.includes(attr.field)}"
                                position="is-top">
                                <b-button
                                    class="control"
                                    :disabled="localCode.data[attr.field].value === ''"
                                    @click="clearField(attr.field)">
                                    <b-icon
                                        icon="eraser"
                                        size="is-small" />
                                </b-button>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
                <div
                    v-for="(field, i) in computedFields(localCode)"
                    :key="`${i}-${field.value}`">
                    <div class="field is-horizontal">
                        <label class="field-label label pad-top">
                            <span>{{ field.label }}:</span>
                        </label>
                        <div class="field-body-column has-margin-top field is-grouped has-addons">
                            <div class="control is-expanded">
                                <b-input
                                    :model-value="field.value"
                                    disabled />
                            </div>
                            <b-tooltip
                                class="control"
                                :label="'Copy value'"
                                position="is-top">
                                <b-button
                                    class="button"
                                    :disabled="!field.hasValue"
                                    @click="copyTextToClipboard(field.value)">
                                    <b-icon
                                        pack="fa"
                                        icon="copy"
                                        size="is-small" />
                                </b-button>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-grouped is-grouped-right has-margin-top2">
                <p class="control">
                    <b-button
                        @click="$emit('cancel')">
                        <b-icon
                            icon="close-circle"
                            size="is-small" />
                        <span>Cancel</span>
                    </b-button>
                </p>
                <p class="control">
                    <b-button
                        v-require-can-edit-code="{ libraryName: library, scopes: transformedScopes }"
                        type="is-primary"
                        :disabled="!canSubmit"
                        :class="{'is-loading': saveLoading}"
                        @click="saveCode">
                        <b-icon
                            icon="content-save"
                            size="is-small" />
                        <span>{{ submitButtonLabel }}</span>
                    </b-button>
                </p>
            </div>
        </form>
        <code-edit-save-progress
            :save-process-started="saveLoading"
            :release-id="saveResultReleaseId"
            :external-error="saveError"
            :is-release-empty="isReleaseEmpty"
            :is-release-chain="isReleaseChain"
            @success="onSaveProgressSuccess"
            @close="onSaveProgressClose" />
    </spinner>
</template>

<script>
    import _ from 'lodash';
    import debounce from 'lodash/debounce';
    import CodeEditSaveProgress from './CodeEditSaveProgress.vue';
    import BoolElement from './BoolElement.vue';
    import BoolSelector from './BoolSelector.vue';
    import CodeRefSelector from './CodeRefSelector.vue';
    import LibrarySelector from './LibrarySelector.vue';
    import DateTimePicker from './DateTimePicker.vue';
    import Spinner from './Spinner.vue';
    import { showMixin } from '../mixins/showMixin';
    import copyToClipboard from '@/shared/mixins/copyToClipboard';
    import {
        checkIdentityConflict,
        createChangeDoc,
        createChangeDocAndCommit,
        createReleaseChain,
        fetchCodeEditContext
    } from '../helpers/api';
    import { attributeTypeToInputType, attributeTypeToStepSize, decodeIdBase64 } from '../helpers/utils.js';
    import { getCodeLink } from '../helpers/routing.js';
    import {
        createCodeDefArray,
        createCodeObject3,
        transformToChangeDocCode2,
        transformToChangeDocCodeIdentityFieldsOnly
    } from '../helpers/dataHelpers.js';
    import { requireCanEditCode } from '@/shared/directives/requirePermission.js';
    import { InputRegexValidator } from '@/shared/helpers/inputRegexValidator.ts';
    import { getNameLabel } from '@/shared/helpers/nameWithAliasHelper.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEditSaveProgress,
            BoolElement,
            BoolSelector,
            CodeRefSelector,
            DateTimePicker,
            Spinner,
            LibrarySelector
        },
        mixins: [
            showMixin,
            copyToClipboard
        ],
        props: {
            library: {
                type: String,
                required: true
            },
            scopes: {
                type: [Object, String],
                required: false,
                default: null
            },
            codeId: {
                type: String,
                required: false,
                default: null
            },
            codeSetId: {
                type: String,
                required: false,
                default: null
            },
            codeSetName: {
                type: String,
                required: false,
                default: null
            },
            quickCommit: {
                type: Boolean,
                required: false,
                default: false
            },
            submitButtonLabel: {
                type: String,
                required: false,
                default: 'Save'
            },
            codeTemplateValues: {
                type: Object,
                required: false,
                default: null
            },
            customLabels: {
                type: Object,
                required: false,
                default: null
            },
            formTitle: {
                type: String,
                required: false,
                default: null
            },
            formTitleClass: {
                type: String,
                required: false,
                default: null
            },
            referableCodesFilters: {
                type: Object,
                required: false,
                default: () => {}
            },
            referableCodesNotToLoad: {
                type: Array,
                required: false,
                default: () => []
            },
            referenceLibraryQueryOverrides: {
                type: Object,
                required: false,
                default: () => {}
            },
            fieldsToDisable: {
                type: Array,
                required: false,
                default: () => []
            },
            useBoxRender: {
                type: Boolean,
                required: false,
                default: true
            },
            useColumns: {
                type: Boolean,
                required: false,
                default: false
            },
            fieldsToIgnore: {
                type: Array,
                required: false,
                default: () => []
            },
            beforeSaveCode: {
                type: Function,
                default: () => {}
            },
            releaseChainFactory: {
                type: Function,
                required: false,
                default: null
            },
            computedFields: {
                type: Function,
                default: () => []
            }
        },
        emits: [
            'refresh',
            'cancel'
        ],
        data() {
            return {
                code: null,
                localCode: null,
                loading: true,
                saveLoading: false,
                saveError: null,
                calendar: null,
                isDirty: true,
                isIdentityConflictChecked: false,
                isIdentityConflict: false,
                isReleaseChain: false,
                identityConflictMessage: null,
                identityConflictName: null,
                identityConflictId: null,
                attributes: null,
                referableCodes: {},
                codeSet: null,
                codeNameRegex: null,
                codeDescription: null,
                saveCodesPayload: null,
                saveResultReleaseId: null,
                isReleaseEmpty: false,
                nameAlias: null
            };
        },
        computed: {
            requiredMissing() {
                if (!this.attributes || !this.localCode.data) return true;

                const desc = this.attributes.find(a => a.field === 'description');
                if (this.codeId && this.code.data[desc.field].value === this.localCode.data[desc.field].value) {
                    // if code has already been persisted, always accept original description
                    return false;
                }
                if (desc.required && !this.localCode.data[desc.field].value.trim().length) {
                    return true;
                }

                return this.attributes
                    .filter(a => !this.isVisible(a))
                    .some(a => a.required && this.localCode.data[a.field].value === '');
            },
            referableCodesFiltered() {
                if (this.referableCodesFilters) {
                    const filteredCodes = {};

                    Object.keys(this.referableCodes).forEach(key => {
                        if (Object.keys(this.referableCodesFilters).includes(key)) {
                            filteredCodes[key] = this.referableCodes[key].filter(this.referableCodesFilters[key]);
                        } else {
                            filteredCodes[key] = this.referableCodes[key];
                        }
                    });

                    return filteredCodes;
                }

                return this.referableCodes;
            },
            titleClass() {
                const classes = {
                    'is-2': this.formTitleClass === null,
                    'title': true
                };
                if (this.formTitleClass !== null) {
                    classes[this.formTitleClass] = true;
                }
                return classes;
            },
            canSubmit() {
                return !this.requiredMissing
                    && this.isDirty
                    && (this.codeId || this.isIdentityConflictChecked && !this.isIdentityConflict);
            },
            transformedScopes() {
                if (Array.isArray(this.scopes)) {
                    return this.scopes;
                } else if (typeof this.scopes === 'string') {
                    return [this.scopes];
                } else {
                    return [];
                }
            },
            dataForCheckIdentityConflict() {
                if (this.codeId) return null;
                if (this.requiredMissing || !this.isDirty) return null;

                return transformToChangeDocCodeIdentityFieldsOnly(this.localCode.data, this.attributes);
            },
            regexValidators() {
                const validators = {
                    name: this.makeValidator(this.codeNameRegex),
                    description: this.makeValidator(this.codeDescriptionRegex)
                };

                this.attributes.forEach(attribute => {
                    if (attribute.regex) {
                        validators[attribute.field] = this.makeValidator(attribute.regex);
                    }
                });
                return validators;
            }
        },
        watch: {
            localCode: {
                handler(localCode) {
                    const hasDangerFieldType = this.attributes
                        .some(attr => this.getFieldType(attr, localCode.data[attr.field].value, this.code.data[attr.field].value) === 'is-danger');
                    this.isDirty = hasDangerFieldType
                        ? false
                        : this.attributes
                            .some(attr => this.code.data[attr.field].value !== localCode.data[attr.field].value);
                },
                deep: true
            },
            dataForCheckIdentityConflict: debounce(async function(newValue, oldValue) {
                if (newValue === null) {
                    this.isIdentityConflictChecked = false;
                    return;
                }

                const newSerialized = JSON.stringify(newValue);
                const oldSerialized = JSON.stringify(oldValue);

                if (newSerialized === oldSerialized) {
                    this.isIdentityConflictChecked = true;
                    return; // Nothing important have changed
                }

                const result = (await checkIdentityConflict(this, this.codeSetName, newValue)).data;

                this.isIdentityConflict = result.conflict;
                if (this.isIdentityConflict) {
                    this.identityConflictMessage = result.message;
                    this.identityConflictName = result.identity;
                    this.identityConflictId = result.id;
                }
                this.isIdentityConflictChecked = true;
            }, 500 /* milliseconds wait time */)
        },
        async mounted() {
            let resultItem = null;
            if (this.codeId) {
                resultItem = (await fetchCodeEditContext(this, {
                    codeId: decodeIdBase64(this.codeId),
                    codeRefsNotToLoad: this.referableCodesNotToLoad,
                    referenceLibraryQueryOverrides: this.referenceLibraryQueryOverrides
                })).data;
                this.attributes = this.createCodeDefArrayWithCustomLabels(resultItem.library);
                this.code = createCodeObject3(
                    resultItem.code,
                    this.attributes.map(a => a.field)
                );
            } else if (this.codeSetId || this.codeSetName) {
                const codeSetId = this.codeSetId ? decodeIdBase64(this.codeSetId) : null;
                resultItem = (await fetchCodeEditContext(this, {
                    codeSetId: codeSetId,
                    codeSetName: this.codeSetName,
                    codeRefsNotToLoad: this.referableCodesNotToLoad,
                    referenceLibraryQueryOverrides: this.referenceLibraryQueryOverrides
                })).data;
                this.attributes = this.createCodeDefArrayWithCustomLabels(resultItem.library);
                this.code = this.createTemplateObj();
            } else {
                throw 'either codeId or codeSet must be set, first for existing, last for new (both will be existing)';
            }

            this.referableCodes = resultItem.referableCodes;
            this.codeSet = resultItem.codeSet;
            this.localCode = JSON.parse(JSON.stringify(this.code));

            if (resultItem.codeNameRegex) {
                this.codeNameRegex = resultItem.codeNameRegex;
            }

            if (resultItem.codeDescriptionRegex) {
                this.codeDescriptionRegex = resultItem.codeDescriptionRegex;
            }

            if (resultItem.library.nameAlias) {
                this.nameAlias = resultItem.library.nameAlias;
            }
            this.loading = false;
        },
        methods: {
            isSelectorDisabled(attr) {
                return this.codeId && attr.includeInIdentity || this.fieldsToDisable.includes(attr.field);
            },
            isSelectorReferenceLoadingIgnored(attr) {
                return this.referableCodesNotToLoad.includes(attr.field);
            },
            createCodeDefArrayWithCustomLabels(library) {
                const defArray = createCodeDefArray(library.attributeDefinitions, {
                    // Note: If name may not be changed, it must be treated as in identity (not editable)
                    nameInIdentity: library.nameInIdentity || !library.nameMayChange,
                    descriptionIsRequired: library.descriptionIsRequired,
                    descriptionRegex: library.descriptionRegex
                });
                if (this.customLabels) {
                    Object.keys(this.customLabels).forEach(key => {
                        const idx = _.findIndex(defArray, x => x.field === key);
                        if (idx > -1) {
                            defArray[idx].label = this.customLabels[key];
                        }
                    });
                }

                return defArray;
            },
            createTemplateObj() {
                const values = this.attributes
                    .map(c => c.field)
                    .reduce(
                        (o, key) => ({ ...o, [key]: { name: key, value: '' } }),
                        {}
                    );
                this.attributes.forEach(a => {
                    if (a.type === 'BOOL' || a.type === 'Bool') values[a.field].value = 'False';
                });

                values['isValid'].value = 'True';

                // HOOK in preset values here....!
                if (this.codeTemplateValues) {
                    Object.keys(this.codeTemplateValues).forEach(key => {
                        if (Object.prototype.hasOwnProperty.call(values, key)) {
                            const templateValue = this.codeTemplateValues[key];
                            if (templateValue instanceof Function) {
                                values[key].value = templateValue();
                            } else {
                                values[key].value = templateValue;
                            }
                        }
                    });
                }

                return { id: '', data: values };
            },
            clearField(field) {
                this.localCode.data[field].value = '';
            },
            async saveCode() {
                this.saveLoading = true;

                try {
                    await this.beforeSaveCode(this.localCode.data, this.referableCodes);
                    const codes = [transformToChangeDocCode2(this.localCode.data, this.codeId ? this.code.data : null)];
                    this.saveCodesPayload = codes;
                    const res = await this.createChange(codes);
                    if (res.data.message) {
                        this.showInfo(res.data.message);
                    }
                    this.isDirty = false;
                    if (res.data.releaseId > 0) {
                        this.saveResultReleaseId = res.data.releaseId;
                    } else {
                        this.isReleaseEmpty = true;
                    }
                } catch (err) {
                    this.saveError = err;
                } finally {
                    this.saveLoading = false;
                }
            },
            async createChange(codes) {
                if (this.releaseChainFactory) {
                    const chain = this.releaseChainFactory([{
                        codeSetName: this.codeSet,
                        libraryName: this.library,
                        codes: codes
                    }]);
                    this.isReleaseChain = true;
                    return await createReleaseChain(this, chain);
                } else if (this.quickCommit) {
                    return await createChangeDocAndCommit(this, this.codeSet, codes);
                } else {
                    return await createChangeDoc(this, this.codeSet, codes);
                }
            },
            onSaveProgressSuccess() {
                this.isReleaseChain = false;
                this.$emit('refresh', this.saveCodesPayload);
            },
            onSaveProgressClose() {
                this.isReleaseChain = false;
                this.$emit('cancel', this.saveCodesPayload);
            },
            isVisible(attr) {
                return ['identity', 'dateCreated', 'dateUpdated', 'commonLibraryIRI', 'attachmentKey'].includes(attr.field);
            },
            isSvgAttributeType(type) {
                return type.toUpperCase() === 'SVG';
            },
            getFieldType(attribute, attributeValue, originalAttributeValue) {
                const validationError = this.invalidInputError(attribute);
                if (validationError)
                    return this.codeId && originalAttributeValue === attributeValue ? 'is-warning' : 'is-danger';
                return '';
            },
            showMaxLength(attribute) {
                return this.invalidInputError(attribute)
                    ? this.regexValidators[attribute.field]?.maxLength
                    : undefined;
            },
            invalidInputError(attribute) {
                return this.regexValidators[attribute.field]?.validate(this.localCode.data[attribute.field].value)?.error;
            },

            makeValidator(regex) {
                return regex ? new InputRegexValidator(new RegExp(regex)) : null;
            },
            attributeTypeToInputType,
            attributeTypeToStepSize,
            getCodeLink,
            getNameLabel
        }
    };
</script>

<style scoped>
    .hide {
        visibility: hidden;
    }
    .pad-top {
        padding-top: 15px;
    }
    .columns-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 50vh;
        padding-right: 5px;
    }
    .field-body-column {
        display: flex;
        flex-basis: 0;
        flex-grow: 2;
        flex-shrink: 1;
    }
</style>

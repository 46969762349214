<template>
    <div class="container">
        <b-field>
            <router-link :to="getSchemaLink(identity)">
                &laquo; Back to schema
            </router-link>
        </b-field>
        <h1 class="title">
            Edit attribute mapping in schema {{ identity }} towards schema
            <span>
                <b-dropdown
                    :triggers="['hover']"
                    aria-role="list">
                    <template #trigger="{ active }">
                        <b-button
                            :label="schemaDropdownLabel"
                            :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>
                    <b-dropdown-item
                        v-for="(schema, index) in schemas"
                        :key="index"
                        aria-role="listitem"
                        @click="selectSchema2(schema)">
                        {{ schema.Name }}
                    </b-dropdown-item>
                </b-dropdown>
            </span>
        </h1>

        <spinner :loading="loadingComponent">
            <div
                id="outer-columns"
                class="columns">
                <div class="column">
                    <!-- Options -->
                    <div class="columns">
                        <div class="column">
                            <h5 class="subtitle column-header-light">
                                Attribute Selector Options
                            </h5>
                            <b-field>
                                <b-switch v-model="groupAttributesByInterface">
                                    Group attributes by interface
                                </b-switch>
                            </b-field>
                            <b-field>
                                <b-switch v-model="classFilterEnabled">
                                    Filter attributes by class
                                </b-switch>
                            </b-field>
                        </div>
                    </div>
                    <!-- Picker -->
                    <div class="columns">
                        <div class="column is-half">
                            <b-field
                                v-if="classFilterEnabled"
                                label="Showing attributes on class:">
                                <b-select
                                    v-model="class1">
                                    <option :value="null" />
                                    <option
                                        v-for="class_ in schema1Classes"
                                        :key="class_.id"
                                        :value="class_">
                                        {{ class_.Name }}
                                    </option>
                                </b-select>
                            </b-field>

                            <h5
                                class="subtitle column-header-light">
                                Schema 1: {{ identity }} Attributes
                            </h5>

                            <div class="table-overflow">
                                <b-table
                                    v-if="filteredAttributes1.length > 0"
                                    v-model:selected="selectedAttribute1"
                                    :hoverable="true"
                                    :data="filteredAttributes1"
                                    class="cursorPointer"
                                    :mobile-cards="false"
                                    @click="clickSelectAttribute1">
                                    <b-table-column
                                        v-slot="props"
                                        :visible="visibleAttributeColumns.length > 1"
                                        field="InterfaceName"
                                        label="Interface">
                                        {{ props.row.InterfaceName }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        field="Name"
                                        label="Name">
                                        <decorated-paragraph
                                            :prefix="props.row.Name"
                                            :decorated-text="props.row.MappingRef ? ` → ${props.row.MappingRef}` : undefined"
                                            :style-object="{ fontWeight: 'bold'}" />
                                    </b-table-column>
                                </b-table>
                                <span v-else>Found no attributes in {{ identity }} </span>
                            </div>
                        </div>
                        <div class="column is-half">
                            <b-field
                                v-if="classFilterEnabled"
                                label="Showing attributes on class:"
                                :type="class2Error ? 'is-danger' : null"
                                :message="class2Error">
                                <b-input
                                    disabled
                                    style="width: fit-content; min-width: 100px"
                                    :model-value="class2 ? class2.Name : null">
                                    />
                                </b-input>
                            </b-field>

                            <h5
                                class="subtitle column-header-light">
                                Schema 2:
                                {{
                                    selectedSchema2 ?
                                        `${selectedSchema2.Name} Attributes`
                                        : "select second schema"
                                }}
                            </h5>

                            <div
                                v-if="filteredAttributes2.length > 0"
                                class="table-overflow">
                                <spinner :loading="loadingSchema2Attributes">
                                    <generic-table-select
                                        :selected-sync="selectedAttribute2"
                                        :data="filteredAttributes2"
                                        :columns="visibleAttributeColumns"
                                        :sticky-header="groupAttributesByInterface"
                                        @clickElement="clickSelectAttribute2" />
                                </spinner>
                            </div>
                            <span v-else-if="selectedSchema2 && !loadingSchema2Attributes">
                                No attributes in {{ selectedSchema2.Name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <!-- Form -->
                    <div class="columns">
                        <div class="column">
                            <h5 class="subtitle column-header-light">
                                {{ formHeader }}
                            </h5>
                            <schema-attribute-mapping-editor-form
                                v-if="selectedMapping"
                                :key="uniqueKey"
                                :mapping="selectedMapping"
                                :schema1-attributes="filteredAttributes1"
                                :library-definitions="libraryDefinitions"
                                @refresh="onSave"
                                @clickCancel="onCancel" />
                            <schema-attribute-mapping-editor-form
                                v-else-if="creatingNewMapping"
                                :key="uniqueKey+1000"
                                :creating-new="true"
                                :mapping="newMapping"
                                :schema1-attributes="filteredAttributes1"
                                :library-definitions="libraryDefinitions"
                                @refresh="onSave"
                                @clickCancel="onCancel" />
                            <p v-else-if="selectedAttribute1 && selectedSchema2">
                                No mapping found for <b>{{ selectedAttribute1.Name }}</b>
                                from <b>{{ identity }}</b> to <b>{{ selectedSchema2.Name }}</b>.
                                Select an attribute in <b>{{ selectedSchema2.Name }}</b> to create a new mapping.
                            </p>
                            <p v-else>
                                Select attribute 1 and schema 2.
                            </p>
                            <br>
                            <b-button
                                v-if="possibleToCreateNewMapping"
                                v-require-can-edit-code="{ libraryName: 'SchemaMapAttribute' }"
                                type="is-primary"
                                @click="createNewMapping">
                                New mapping
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </spinner>
    </div>
</template>

<script>
    import DecoratedParagraph from '@/shared/components/DecoratedParagraph.vue';
    import GenericTableSelect from '@/shared/components/GenericTableSelect.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText, getLibraryWithScopes } from '@/shared/helpers/api';
    import { generateNameByConvention } from '@/shared/helpers/schemaHelpers';
    import SchemaAttributeMappingEditorForm from './SchemaAttributeMappingEditorForm.vue';
    import { compareByProperty } from '@/shared/helpers/utils.js';
    import { getSchemaLink } from '@/apps/schema/schemaHelper.js';
    import { useSchemaEditStore } from '@/stores/schemaEditStore.js';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaAttributeMappingEditorForm,
            Spinner,
            GenericTableSelect,
            DecoratedParagraph
        },
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                selectedAttribute1: null,
                selectedAttribute2: null,
                schema1Attributes: [],
                schema2Attributes: [],
                schemas: [],
                selectedSchema1: null,
                selectedSchema2: null,
                libraryDefinitions: null,
                mappingsFromSchema: [],
                uniqueKey: 0,
                selectedMapping: null,
                creatingNewMapping: false,
                newMapping: null,
                loadingComponent: false,
                loadingSchema2Attributes: false,
                classFilterEnabled: false,
                schema1Classes: [],
                classMappingsFromSchema1: null,
                class1: null,
                interfacesForClasses: null,
                groupAttributesByInterface: false,
                schemaEditStore: useSchemaEditStore()
            };
        },
        computed: {
            filteredAttributes1() {
                let relevantAttributes = [];
                if (this.classFilterEnabled && this.class1 !== null) {
                    const relevantInterfaces = this.interfacesForClasses[this.class1.Identity] || new Set();
                    relevantAttributes = this.schema1Attributes.filter(attr => relevantInterfaces.has(attr.Interface));
                } else {
                    relevantAttributes = this.schema1Attributes;
                }
                const attributeList = [];
                for (const attr of relevantAttributes) {
                    const mappingsForAttribute = this.filteredMappingsFromToSchema.filter(mapping2 => mapping2.Attribute1 === attr.Identity);
                    if (mappingsForAttribute.length > 0) {
                        for (const mapping of mappingsForAttribute) {
                            const attrWithMapping = { ...attr };
                            attrWithMapping.Mapping = mapping;
                            const attribute2 = this.schema2Attributes.find(attr => attr.Identity === mapping.Attribute2);
                            if (attribute2)
                                attrWithMapping.MappingRef = `${attribute2?.InterfaceName}.${attribute2?.Name}`;
                            attributeList.push(attrWithMapping);
                        }
                    } else {
                        attributeList.push(attr);
                    }
                }

                const sortBy = this.visibleAttributeColumns[0].field;
                attributeList.sort(compareByProperty(sortBy));
                return attributeList;
            },
            filteredAttributes2() {
                return this.filterAndSortAttributeList(this.class2, this.schema2Attributes);
            },
            filteredMappingsFromToSchema() {
                if (this.selectedSchema2) {
                    return this.mappingsFromSchema.filter(mapping => mapping.Schema2 === this.selectedSchema2.Identity);
                }
                return [];
            },
            schemaDropdownLabel() {
                return this.selectedSchema2?.Name ?? 'Select schema 2';
            },
            formHeader() {
                if (this.selectedAttribute1 && this.selectedAttribute2) {
                    const interface1 = this.selectedAttribute1.InterfaceName;
                    const attribute1 = this.selectedAttribute1.Name;
                    const attribute2 = this.selectedAttribute2.Name;
                    const interface2 = this.selectedAttribute2.InterfaceName;
                    return interface1 + '.' + attribute1 + ' — ' + interface2 + '.' + attribute2 + ' mapping properties';
                } else {
                    return 'No mapping selected';
                }
            },
            possibleToCreateNewMapping() {
                return !this.selectedMapping
                    && this.selectedAttribute1
                    && this.selectedAttribute2;
            },
            classMappingsFromSchema1ToSchema2ForClass1() {
                if (!this.classMappingsFromSchema1 || !this.selectedSchema2 || !this.class1) {
                    return [];
                }
                return this.classMappingsFromSchema1
                    .filter(mapping =>
                        mapping.Schema2 === this.selectedSchema2.Identity
                        && mapping.Class1Identity === this.class1.Identity
                    );
            },
            class2() {
                const onlyMapping = this.classMappingsFromSchema1ToSchema2ForClass1[0] || null;
                return onlyMapping
                    ? { Identity: onlyMapping.Class2Identity, Name: onlyMapping.Class2Name }
                    : null;
            },
            class2Error() {
                return this.class1 && this.selectedSchema2 && !this.class2
                    ? `No class mapping from class ${this.class1.Name}.`
                    : null;
            },
            visibleAttributeColumns() {
                return this.groupAttributesByInterface
                    ? [{ field: 'InterfaceName', label: 'Interface' }, { field: 'Name', label: 'Name' }]
                    : [{ field: 'Name', label: 'Name' }];
            }
        },
        watch: {
            loadingSchema2Attributes(truthy) {
                if (truthy) return;

                const attributes = this.schemaEditStore.popMapSchemaObject();

                if (!attributes) return;

                const attribute1 = this.filteredAttributes1.find(attr => attr.Id === attributes.attribute1);
                if (attribute1) {
                    this.selectedAttribute1 = attribute1;
                    this.selectedMapping = attribute1?.Mapping;
                }

                const attribute2 = this.filteredAttributes2.find(attr => attr.Id === attributes.attribute2);
                if (attribute2) {
                    this.selectedAttribute2 = attribute2;
                }
            }
        },
        async created() {
            this.loadingComponent = true;
            await Promise.all([
                this.getSchemas(),
                this.getSchema1Attributes(),
                getLibraryWithScopes(this, 'SchemaMapAttribute')
                    .then(value => this.libraryDefinitions = value[0]),
                this.getMappingsFromSchema(),
                this.getClassesForSchema1(),
                this.getClassMappingsFromSchema1(),
                this.getInterfacesForClasses()
            ]);

            this.loadingComponent = false;

            if (this.schemaEditStore.mapSchemaId) {
                const schemaTwoId = this.schemaEditStore.popMapSchemaId();
                const schema2Object = this.schemas.find(sc2 => sc2.Id === schemaTwoId);
                if (schema2Object) {
                    await this.selectSchema2(schema2Object);
                }
            }
        },
        methods: {
            getSchemaLink,
            loadForm(mapping) {
                this.uniqueKey++;
                this.selectedAttribute2 = this.filteredAttributes2.find(attribute2 => attribute2 && attribute2.Identity === mapping.Attribute2) || null;
                this.selectedMapping = mapping;
            },
            async getMappingsFromSchema() {
                const query = 'FROM SchemaMapAttribute WHERE IsValid = true AND Schema1 = @schema1';
                this.mappingsFromSchema = (await genericViewQueryAsText(
                    this,
                    query,
                    [{ name: '@schema1', value: this.identity }]
                )).data;
            },
            async getSchemas() {
                const query = 'FROM Schema WHERE IsValid = true';
                const allSchemas = (await genericViewQueryAsText(this, query)).data
                    .sort((a, b) => a.Name.localeCompare(b.Name));
                this.selectedSchema1 = allSchemas.find(x => x.Identity === this.identity);
                this.schemas = allSchemas.filter(x => x.Identity !== this.identity);
            },
            async getSchema1Attributes() {
                const query = 'FROM SchemaAttribute SELECT Name, Id, Identity, Interface WHERE IsValid = true AND Schema = ' + this.identity + ' JOIN Interface SELECT Name as InterfaceName END';
                this.schema1Attributes = (await genericViewQueryAsText(this, query)).data;
            },
            async getSchema2Attributes(schema2) {
                const query = 'FROM SchemaAttribute SELECT Name, Id, Identity, Interface WHERE IsValid = true AND Schema = ' + schema2.Name + ' JOIN Interface SELECT Name as InterfaceName END';
                this.schema2Attributes = (await genericViewQueryAsText(this, query)).data;
            },
            async getClassesForSchema1() {
                const query
                    = `FROM SchemaClass
                    WHERE IsValid = true AND Schema = ${this.identity}
                    SELECT Identity, Name
                    ORDER BY Name`;
                this.schema1Classes = (await genericViewQueryAsText(this, query)).data;
            },
            async getClassMappingsFromSchema1() {
                const query
                    = `FROM SchemaMapClass
                    WHERE IsValid = true AND Schema1 = ${this.identity}
                    SELECT Schema1, Schema2, Identity
                    JOIN Class1
                        SELECT Identity as Class1Identity, Name as Class1Name
                    END
                    JOIN Class2
                        SELECT Identity as Class2Identity, Name as Class2Name
                    END`;
                this.classMappingsFromSchema1 = (await genericViewQueryAsText(this, query)).data;
            },

            async getInterfacesForClasses() {
                const query
                    = `FROM SchemaClassInterface
                    WHERE IsValid = true
                    SELECT Class, Interface`;
                const table = (await genericViewQueryAsText(this, query)).data;

                const interfaceMap = Object.create(null);
                for (const { Class, Interface } of table) {
                    (interfaceMap[Class] || (interfaceMap[Class] = new Set())).add(Interface);
                }
                this.interfacesForClasses = interfaceMap;
            },
            clickSelectAttribute1(attribute) {
                const mapping = attribute.Mapping;

                if (mapping) {
                    this.creatingNewMapping = false;
                    this.loadForm(mapping);
                } else {
                    this.creatingNewMapping = false;
                    this.selectedMapping = null;
                    this.selectedAttribute2 = null;
                }
            },
            clickSelectAttribute2(attribute) {
                const hasClickedSameAttribute = this.selectedAttribute2
                    && this.selectedAttribute2.Identity === attribute.Identity;
                const mapping = this.selectedAttribute1.Mapping;
                const selectedAttributehasMapping = mapping?.Attribute2 === attribute.Identity;

                if (!selectedAttributehasMapping && !hasClickedSameAttribute) {
                    this.creatingNewMapping = false;
                    this.selectedMapping = null;
                    this.selectedAttribute2 = attribute;
                } else if (mapping && !hasClickedSameAttribute) {
                    this.loadForm(mapping);
                }
            },
            async selectSchema2(schema2) {
                this.selectedSchema2 = schema2;
                if (schema2) {
                    this.selectedMapping = null;
                    this.creatingNewMapping = false;
                    this.loadingSchema2Attributes = true;
                    await this.getSchema2Attributes(schema2);
                    this.loadingSchema2Attributes = false;
                    if (this.selectedAttribute1) {
                        const mapping = this.selectedAttribute1.Mapping;
                        this.creatingNewMapping = false;
                        this.selectedMapping = null;
                        if (mapping) {
                            this.loadForm(mapping);
                        }
                    }
                }
            },
            filterAndSortAttributeList(class_, schemaAttributes) {
                const attributes = this.classFilterEnabled && class_
                    ? schemaAttributes.filter((attribute) => this.interfacesForClasses[class_.Identity]?.has(attribute.Interface))
                    : [...schemaAttributes];
                const sortBy = this.visibleAttributeColumns[0].field;
                attributes.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
                return attributes;
            },
            createNewMapping() {
                this.newMapping = {
                    Name: generateNameByConvention(this.selectedSchema1, this.selectedAttribute1.Name, this.selectedSchema2, this.selectedAttribute2.Name),
                    Description: `Mapping for ${this.selectedSchema1.Name} ${this.selectedAttribute1.Name} to ${this.selectedSchema2.Name} ${this.selectedAttribute2.Name}`,
                    Attribute1: this.selectedAttribute1.Identity,
                    Attribute1_ID: this.selectedAttribute1.Id,
                    Attribute2: this.selectedAttribute2.Identity,
                    Attribute2_ID: this.selectedAttribute2.Id,
                    Comments: null,
                    Id: null,
                    Identity: null,
                    InternalComments: null,
                    IsBidirectional: true,
                    IsValid: true,
                    Schema1: this.selectedSchema1.Identity,
                    Schema1_ID: this.selectedSchema1.Id,
                    Schema2: this.selectedSchema2.Identity,
                    Schema2_ID: this.selectedSchema2.Id,
                    ValueMapBehaviour: null,
                    ValueMapBehaviour_ID: null,
                    ValueMapColumn1: null,
                    ValueMapColumn2: null,
                    ValueMapContextAttribute1: null,
                    ValueMapContextAttribute1_ID: null,
                    ValueMapContextAttribute2: null,
                    ValueMapContextAttribute2_ID: null,
                    ValueMapContextColumn1: null,
                    ValueMapContextColumn2: null,
                    ValueMapLibrary: null,
                    ValueMapSet: null,
                    ValueMapSetColumn: null
                };
                this.creatingNewMapping = true;
            },
            async onSave() {
                this.loadingComponent = true;
                await this.getMappingsFromSchema();
                this.loadingComponent = false;
                this.selectSchema2(this.selectedSchema2);
            },
            onCancel() {
                this.creatingNewMapping = false;
                this.uniqueKey++;
            }
        }
    };
</script>

<style scoped>
.container {
    max-width: 1920px;
}
.table-overflow :deep(.table-wrapper) {
    max-height: 60vh;
    overflow: auto;
}
@media screen and (max-width: 1440px) {
    #outer-columns {
        display: block;
    }
    .table-overflow :deep(.table-wrapper) {
        max-height: 40vh;
    }
}
</style>

<template>
    <div>
        <div
            v-if="library && virtualLibraryDependencies.length > 0"
            class="block">
            <p class="block">
                This virtual library depend on data from the following libraries.
                Any changes to these libraries may trigger changes in <i>{{ library.name }}</i> as well.
            </p>
            <ul
                v-for="dependency in virtualLibraryDependencies"
                :key="dependency"
                class="bullet-list">
                <li>
                    <router-link
                        :to="getLibraryLink(library.name)">
                        {{ dependency }}
                    </router-link>
                </li>
            </ul>
            <hr>
        </div>
        <div
            v-if="library"
            class="columns is-multiline">
            <div class="subscription-container">
                <div
                    v-if="activeSubscriptions.length !== 0"
                    class="column">
                    <p class="title is-5">
                        Active subscriptions
                    </p>
                    <div class="tags are-medium">
                        <span
                            v-for="(sub, idx) in activeSubscriptions"
                            :key="idx"
                            class="tag is-danger">{{ sub.application.name }}</span>
                    </div>
                </div>
                <div
                    v-if="deactivatedSubscriptions.length !== 0"
                    class="column">
                    <p class="title is-5">
                        Deactivated subscriptions
                    </p>
                    <div class="tags are-medium">
                        <span
                            v-for="(sub, idx) in deactivatedSubscriptions"
                            :key="idx"
                            class="tag is-danger">{{ sub.application.name }}</span>
                    </div>
                </div>
            </div>
            <div
                v-if="webhooks.length !== 0"
                class="column">
                <p class="title is-5">
                    Webhooks
                </p>
                <table class="table is-striped is-fullwidth">
                    <tbody>
                        <tr>
                            <th>Callback Url</th>
                            <th>Status</th>
                            <th>Enabled</th>
                        </tr>
                        <tr
                            v-for="(hook, idx) in webhooks"
                            :key="idx">
                            <td>
                                <b-tooltip
                                    :active="hook.callbackUrl.length > 30"
                                    :label="hook.callbackUrl"
                                    position="is-bottom">
                                    <p>
                                        {{ $filters.truncate(hook.callbackUrl, 30) }}
                                    </p>
                                </b-tooltip>
                            </td>
                            <td>{{ hook.connectionStatus }}</td>
                            <td>
                                <bool-element :value="hook.enabled" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p v-else>
            This library has no subscriptions.
        </p>
    </div>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { getLibraryLink } from '@/shared/helpers/routing';
    import {
        getLibrarySubscriptionsAndWebhooks,
        getVirtualLibraryDependencies
    } from '@/shared/helpers/api.ts';

    export default {
        components: {
            BoolElement
        },
        props: {
            library: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                virtualLibraryDependencies: [],
                hooksAndSubs: null
            };
        },
        computed: {
            webhooks() {
                return this.hooksAndSubs !== null ? this.hooksAndSubs.webhooks : [];
            },
            activeSubscriptions() {
                return this.hooksAndSubs !== null
                    ? this.hooksAndSubs.subscriptions.filter(subscription => subscription.enabled)
                    : [];
            },
            deactivatedSubscriptions() {
                return this.hooksAndSubs !== null
                    ? this.hooksAndSubs.subscriptions.filter(subscription => !subscription.enabled)
                    : [];
            }
        },
        created() {
            this.loadRelationsData();
        },
        methods: {
            async loadRelationsData() {
                this.hooksAndSubs = await getLibrarySubscriptionsAndWebhooks(
                    this,
                    this.library.name
                );

                if (this.library.isVirtual) {
                    this.virtualLibraryDependencies = await getVirtualLibraryDependencies(
                        this,
                        this.library.name
                    );
                }
            },
            getLibraryLink
        }
    };
</script>

<style scoped>
.subscription-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
</style>

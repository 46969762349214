<script setup>
    import { sqlQuery } from '@/shared/helpers/api.ts';
    import { cancelPreviousWhenCalledWithDebouncing } from '@/shared/helpers/utils.js';
    import { http } from '@/shared/httpWrapper.js';
    import { ref, watch } from 'vue';

    const MAX_RESULTS = 50;

    const emit = defineEmits(['selected']);

    const searchText = ref('');
    const searchResults = ref([]);
    const isSearchResultPartial = ref(false);
    const isFetching = ref(false);

    const finishSearch = (results) => {
        if (results.length === MAX_RESULTS + 1) {
            results.pop();
            isSearchResultPartial.value = true;
        } else {
            isSearchResultPartial.value = false;
        }
        searchResults.value = results;
        isFetching.value = false;
    };

    const onSelect = async (option) => {
        // Update parent component with selected option
        emit('selected', option);
    };

    watch(searchText, cancelPreviousWhenCalledWithDebouncing(
        async (abortSignal, readyPromise) => {
            if (!searchText.value || searchText.value.length < 3) {
                finishSearch([]);
                return;
            }

            isFetching.value = true;

            await readyPromise;

            // Ensure numeric searchText is treated as a string by adding quotes since datatype of name column is nvarchar
            const results = await
                sqlQuery(http, {
                    query: `SELECT TOP(${MAX_RESULTS}) Id as id, Name as name, Description as description
                            FROM Project
                            WHERE IsValid = 1
                              AND name LIKE '${searchText.value.replace(/'/g, '')}%'
                            ORDER BY name`
                }).then(response => response.data);

            if (abortSignal.aborted)
                return;

            finishSearch(results);
        },
        { delay: 500 }
    ));
</script>

<template>
    <b-field class="searchField">
        <b-autocomplete
            v-model="searchText"
            :data="searchResults"
            placeholder="Find existing project to link"
            field="title"
            :loading="isFetching"
            icon-right="magnify"
            @select="onSelect">
            <template #default="{option}">
                <div class="media">
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>{{ option.name }}</strong>
                                <br>
                                <small>{{ option.description }}</small>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template
                v-if="isSearchResultPartial"
                #footer>
                Displaying the first {{ MAX_RESULTS }} results. Keep typing to narrow down the list.
            </template>
            <template #empty>
                <div class="has-text-centered">
                    {{
                        searchText?.length < 3 ? 'Type at least 3 characters to search' : 'No results found'
                    }}
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<style scoped>
.headless-table thead {
    display: none;
}

.searchField {
    width: 30ch;
    transition: width 0.25s ease-in-out;
}

.searchField:focus-within {
    width: 100%;
}
</style>
